import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import React from "react";

export default function CustomInputDate({ sx, value, callback, disabled }) {
  const handle = (e) => {
    callback(e.target.value);
  };
  return (
    // <LocalizationProvider dateAdapter={AdapterDayjs}>
    //   <DemoContainer
    //     components={[
    //       "DateField",
    //       "TimeField",
    //       "DateTimeField",
    //       "MultiInputDateRangeField",
    //       "MultiInputTimeRangeField",
    //       "MultiInputDateTimeRangeField",
    //     ]}
    //   >
    //     <DemoItem>
    //       <TimeField
    //         defaultValue={
    //           value
    //             ? dayjs(
    //                 "1990-01-01 " + value,
    //                 "YYYY-MM-DD HH:mm:ss.SSSSSS"
    //               ).utcOffset()
    //             : "20:00"
    //         }
    //         onChange={(newValue) => handle(newValue)}
    //       />
    //     </DemoItem>
    //   </DemoContainer>
    // </LocalizationProvider>
    <input
      style={{
        padding: "6.5px 14px",
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "1.4375em",
        letterSpacing: "0.00938em",
        border: "none",
        borderRadius: "10px",
        width: "100%",
        backgroundColor: "#F0F0F0",
      }}
      type="time"
      defaultValue={
        value
          ? dayjs("1990-01-01 " + value, "YYYY-MM-DD HH:mm:ss.SSSSSS").format(
              "HH:mm"
            )
          : "20:00"
      }
      onChange={handle}
    />
  );
}
