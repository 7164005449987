import { TableCell, TableRow } from "@mui/material";

function Row({ row }) {
  return (
    <TableRow>
      <TableCell component="th" scope="row" align="right">
        {row.id}
      </TableCell>
      <TableCell align="right">{row.domain}</TableCell>
      <TableCell align="right">{row.user}</TableCell>
      <TableCell align="right">{row.created_at || 'N/A'}</TableCell>
    </TableRow>
  );
};

export default Row;