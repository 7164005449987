import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { fetchLogin } from "../../api/api";
import { setRoleCookie, setToken } from "../../config/cookie";
import LoadingButton from "@mui/lab/LoadingButton";
import CustomInput from "../../Components/CustomInput";
import Icon from "../../png/icon.svg";
function Login({ setLogined, setSnack, setRole }) {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const log = async () => {
    if (!state.email || !state.password) return;
    setLoading(true);

    try {
      const res = await fetchLogin(state);

      if (res.success) {
        setToken(res.token);
        setRoleCookie(res.role);
        setRole(res.role);
        setLogined(true);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }

      setLoading(false);
    } catch (err) {
      setSnack({
        text: err ? JSON.stringify(err) : "Error",
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  };

  const handle = (val, target) => {
    setState({ ...state, [target]: val });
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={Icon} style={{ marginBottom: "30px" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "60px 40px",
          backgroundColor: "#F8F8F8",
          borderRadius: "10px",
          width: "100%",
          maxWidth: "400px",
        }}
      >
        <Typography
          sx={{ fontSize: "20px", fontWeight: "700", marginBottom: "15px" }}
        >
          Sign in
        </Typography>

        <CustomInput
          placeholder="Email"
          type="email"
          callback={(val) => handle(val, "email")}
          sx={{ margin: "20px 0px", width: "100%" }}
        />

        <CustomInput
          placeholder="Password"
          type="password"
          callback={(val) => handle(val, "password")}
          sx={{ width: "100%" }}
        />

        <LoadingButton
          type="contained"
          size="medium"
          loading={loading}
          onClick={log}
          sx={{
            marginTop: "20px",
            color: "white",
            backgroundColor: "#4669FA",
            width: "100%",
            "&:hover": {
              backgroundColor: "#2651fc",
            },
          }}
        >
          Sign in
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default Login;
