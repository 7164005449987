import { useState, useEffect } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { fetchFiltersAffiliates, fetchLeadFraudInfo } from "../api/api";
import { getTokenValue } from "../config/cookie";
import GlobalLoader from "./GlobalLoader";
import { fraudHeadCells } from "../config/heads";

export default function FraudInfo({ id }) {
  const [fraudLogs, setFraudLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLeadFraudLogs = async () => {
    try {
      const res = await fetchLeadFraudInfo(getTokenValue(), id);
      if (res.success) {
        setFraudLogs([...res.data]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLeadFraudLogs()
  }, [id]);

  return (
    <>
      {loading ? <GlobalLoader style={{ height: "300px" }} /> :
        fraudLogs.length ? (
        <TableContainer component={Paper} className="herehere">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {fraudHeadCells.map((item, i) => (
                  <TableCell align={i === 0 ? "left" : "right"} key={i}>
                    {item.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fraudLogs.map((row) => (
                <TableRow
                  key={row.affiliate}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px" }}>
                    {row.name}
                  </TableCell>
                  <TableCell align="left">
                    {row.source}
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px" }}>
                    {row.created_at}
                  </TableCell>
                  <TableCell align="left">
                    {row.landing ? row.landing : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        ) : <p style={{ fontSize: "1.25rem", fontWeight: "500", lineHeight: "2rem", color: "hsl(232 14% 68%)", textAlign: "center" }}>The lead has no fraud info</p>
      }
    </>
  );
}
