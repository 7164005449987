import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import {
  fetchAffiliates,
  fetchFiltersCampaigns,
  fetchLead,
  fetchUpdateAffiliate,
  fetchUpdateStatus,
  fetchUsers,
  fetchCountries,
} from "../../api/api";
import { getTokenValue } from "../../config/cookie";
import {
  Box,
  Collapse,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { affiliateHeadCells } from "../../config/heads";
import SettingsIcon from "@mui/icons-material/Settings";
import ModalAffiliates from "../../Components/ModalAffiliates";
import GlobalLoader from "../../Components/GlobalLoader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import CustomSelect from "../../Components/CustomSelect";

const visDate = (date) => {
  const _date = date.split("");
  _date.length = 5;
  return _date.join("");
};

const getCampaigns = (campaigns, camp) => {
  if (!campaigns.length) return;
  const _camp = campaigns.find((item) => item.id === camp);
  return _camp?.name;
};

const useStyles = makeStyles((theme) => ({
  cont: {
    width: "100%",
    marginTop: "20px",
  },
  head: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
  },
  title: {
    fontSize: "30px",
  },
  row: {
    "&:last-child td, &:last-child th": { border: 0 },
    "& .MuiTableCell-root": {
      padding: "10px",
      border: "none",
    },
  },
}));

const Row = ({ row, i, handleStatus, handleModal, campaigns, classes }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        key={row.id}
        className={classes.row}
      >
        <TableCell>
          {row.campaigns ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            <DoNotDisturbIcon />
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="right">{row.name}</TableCell>
        <TableCell align="right">
          <Switch
            checked={Boolean(row.status)}
            onChange={() => handleStatus(row.id, !row.status)}
          />
        </TableCell>
        <TableCell align="right">{row.current_daily}</TableCell>
        <TableCell align="right">{row.max_daily}</TableCell>
        <TableCell align="right">{visDate(row.start_traffic)}</TableCell>
        <TableCell align="right">{visDate(row.stop_traffic)}</TableCell>
        <TableCell align="right">
          <IconButton onClick={() => handleModal(row.id)}>
            <SettingsIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow sx={{ backgroundColor: "#e5e7eb" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Campaigns
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#e5e7eb" }}>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.campaigns?.map((item) => (
                    <TableRow key={`campaigns-${item}`}>
                      <TableCell component="th" scope="row">
                        {item}
                      </TableCell>
                      <TableCell>{getCampaigns(campaigns, item)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default function Affiliates({ setSnack }) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [campaigns, setCampaigns] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [currentRegion, setCurrentRegion] = useState('');
  const [totalCurrentQuota, setTotalCurrentQuota] = useState(0);
  const [totalDailyQuota, setTotalDailyQuota] = useState(0);

  const handleRegion = (val) => {
    setCurrentRegion(val);
    localStorage.setItem("currentRegion", val);
  };

  const getCountries = async () => {
    setLoading(true);
    try {
      const res = await fetchCountries(getTokenValue());
      if (res.success) {
        const transformedData = res.data.map(item => ({
          id: item.id,
          name: item.name,
          title: item.name,
        }));
        setRegions([...transformedData]);
        setCurrentRegion(localStorage.getItem("currentRegion")
          ? localStorage.getItem("currentRegion")
          : transformedData[0].name);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setLoading(false);
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  }

  const getCampaignsData = async () => {
    setLoading(true);
    try {
      const res = await fetchFiltersCampaigns(getTokenValue());
      if (res.success) {
        setCampaigns([...res.data]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setLoading(false);
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  };

  const getUsers = async () => {
    try {
      const res = await fetchUsers(getTokenValue());
      if (res.success) {
        setUsers(res.data.users);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: JSON.stringify(err),
        type: "error",
        open: true,
      });
    }
  };

  const getAffiliates = async () => {
    setLoading(true);
    try {
      const regionId = regions?.find(item => item.name === currentRegion)?.id;
      const params = `country_id=${regionId}`;
      const res = await fetchAffiliates(getTokenValue(), params);
      if (res.success) {
        setData([...res.data]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setLoading(false);
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  };



  useEffect(() => {
    getCountries()

  }, []);

  useEffect(() => {
    if (currentRegion) {
      getAffiliates();
    }
  }, [currentRegion]);

  useEffect(() => {
    let totalCurrent = 0;
    let totalDaily = 0;

    for (let item of data) {
      totalCurrent += +item.current_daily;
      totalDaily += +item.max_daily;
    }

    setTotalCurrentQuota(totalCurrent);
    setTotalDailyQuota(totalDaily);
  }, [data]);

  useEffect(() => {
    getCampaignsData();
    getUsers();
  }, [currentRegion]);

  const handleStatus = async (id, status) => {
    try {
      const res = await fetchUpdateStatus(getTokenValue(), { id, status });
      if (res.success) {
        const _data = [...data];
        const index = _data.findIndex((item) => item.id === id);
        _data[index].status = status;
        setData([..._data]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  };

  const handleAffiliate = async () => {
    try {
      const res = await fetchUpdateAffiliate(getTokenValue(), { ...modalData });
      if (res.success) {
        const _data = [...data];
        const index = _data.findIndex((item) => item.id === modalData.id);
        _data[index] = { ...modalData };
        setData([..._data]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  };

  const handleModal = (id) => {
    const _data = [...data];
    const index = _data.findIndex((item) => item.id === id);
    setModalData({ ..._data[index] });
    setOpen(true);
  };
  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <>
          {open ? (
            <ModalAffiliates
              open={open}
              setOpen={setOpen}
              data={modalData}
              setData={setModalData}
              campaigns={campaigns}
              users={users}
              callback={handleAffiliate}
              setSnack={setSnack}
            />
          ) : null}
          <Box className={classes.cont}>
            <Box className={classes.head}>
              <Typography sx={{ fontSize: "20px" }}>Affiliates</Typography>
              <CustomSelect
                value={currentRegion}
                options={regions}
                placeholder={"select region"}
                callback={handleRegion}
                sx={{ backgroundColor: "#FFF" }}
              />
            </Box>

            <TableContainer 
              sx={{ padding: "1.25rem 1.25rem 2.25rem", boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16)", }}  
              component={Paper}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {affiliateHeadCells.map((item, i) => (
                      <TableCell align={i === 0 ? "left" : "right"} key={item.id}>
                        {item.id === "current_daily"
                          ? item.label + ` (${totalCurrentQuota})`
                          : item.id === "max_daily"
                          ? item.label + ` (${totalDailyQuota})`
                          : item.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, i) => (
                    <Row
                      key={row.id}
                      row={row}
                      i={i}
                      handleStatus={handleStatus}
                      handleModal={handleModal}
                      campaigns={campaigns}
                      classes={classes}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
}
