import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { predefinedRanges } from "../config/dateRanges";

export default function CustomDateRange({ sx, value, callback, disabled }) {
  const handle = (val) => {
    callback(val);
  };
  return (
    <DateRangePicker
      ranges={predefinedRanges}
      placeholder="Pick date"
      placement="bottomEnd"
      value={value}
      style={{ width: 300, backgroundColor: "#F0F0F0" }}
      onChange={(val) => handle(val)}
      sx={{ backgroundColor: "#F0F0F0" }}
      menuStyle={{ zIndex: "1301" }}
    />
  );
}
