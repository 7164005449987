import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  fetchDeleteKey,
  fetchGenerateKey,
  fetchKeys,
  fetchCountries,
  fetchUpdateKey,
} from "../../api/api";
import { getTokenValue } from "../../config/cookie";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomInput from "../../Components/CustomInput";
import GlobalLoader from "../../Components/GlobalLoader";
import CustomSelect from "../../Components/CustomSelect";
import { regions } from "../../config/regions";
import DeleteIcon from "@mui/icons-material/Delete";
const useStyles = makeStyles((theme) => ({
  cont: {
    width: "100%",
    marginTop: "20px",
  },
  head: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
  },
  box: {
    display: "flex",
    alignItems: "center",
    marginBottom: "25px",
  },
  list: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    padding: "1.25rem 1.25rem 2.25rem",
    backgroundColor: "#FFF",
    borderRadius: "0.375rem",
    boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16)",
  },
  keyCont: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "48%",
  },
  keyName: {
    maxWidth: "150px",
    width: "150px",
    hyphens: "manual",
    wordBreak: "break-all",
    cursor: "help",
  },
}));

export default function Keys({ setSnack }) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [currentRegion, setCurrentRegion] = useState('');

  const getCountries = async () => {
    try {
      const res = await fetchCountries(getTokenValue());
      if (res.success) {
        const transformedData = res.data.map(item => ({
          id: item.id,
          name: item.name,
          title: item.name,
        }));
        setRegions([...transformedData]);
        setCurrentRegion(localStorage.getItem("currentRegion")
          ? localStorage.getItem("currentRegion")
          : transformedData[0].name);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  }

  const handleRegion = (val) => {
    setCurrentRegion(val);
    localStorage.setItem("currentRegion", val);
  };

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    setSnack({
      text: "Copied",
      type: "info",
      open: true,
    });
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    getKeys();
  }, [currentRegion]);

  const getKeys = async () => {
    setLoading(true);
    try {
      const res = await fetchKeys(getTokenValue());
      if (res.success) {
        setData([...res.data]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setLoading(false);
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  };

  const generateKey = async () => {
    try {
      const res = await fetchGenerateKey(getTokenValue(), { name: key });
      if (res.success) {
        getKeys();
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setKey("");
    } catch (err) {
      setKey("");
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  };

  const deleteKey = async (id) => {
    try {
      const res = await fetchDeleteKey(getTokenValue(), { id });
      if (res.success) {
        getKeys();
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setKey("");
    } catch (err) {
      setKey("");
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  };

  const updateStatus = (id, status) => {
    const _keys = [...data];

    const index = _keys.findIndex((item) => item.id === id);
    _keys[index].status = status;

    setData([..._keys]);
  };

  const updateKey = async (id, e) => {
    const status = e.target.checked;
    try {
      const res = await fetchUpdateKey(getTokenValue(), { id, status });
      if (res.success) {
        updateStatus(id, status);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setKey("");
    } catch (err) {
      setKey("");
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  };

  const handleKey = (val) => {
    setKey(val);
  };

  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Box className={classes.cont}>
          <Box className={classes.head}>
            <Typography sx={{ fontSize: "20px" }}>Api keys</Typography>
            <CustomSelect
              value={currentRegion}
              options={regions}
              placeholder={"select region"}
              callback={handleRegion}
              sx={{ backgroundColor: "#FFF" }}
            />
          </Box>
          <Box 
            className={classes.box}
            sx={{ 
              marginBottom: "15px", 
              padding: "1.25rem", 
              backgroundColor: "#FFF",  
              borderRadius: "0.375rem",
              boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16)", 
            }} 
          >
            <CustomInput
              placeholder={"Key name"}
              callback={handleKey}
              value={key}
            />
            <Button
              variant="contained"
              sx={{ marginLeft: "25px" }}
              onClick={generateKey}
            >
              Generate key
            </Button>
          </Box>

          <Box className={classes.list}>
            {data.map((item) => (
              <Box className={classes.keyCont} key={item.id}>
                <Tooltip title={item.name ? item.name : "Unnamed key"}>
                  <Typography className={classes.keyName}>
                    {item.name ? (item.name.length > 12 
                    ? `${item.name.slice(0, 9)}... :` : `${item.name}:`)
                      : "Unnamed key:"}
                  </Typography>
                </Tooltip>
                <Box
                  onClick={() => copyText(item.key)}
                  sx={{ cursor: "pointer" }}
                >
                  <CustomInput
                    value={item.key}
                    sx={{
                      width: "350px",
                      cursor: "pointer",
                      "& .Mui-disabled": { cursor: "pointer" },
                    }}
                    disabled
                  />
                </Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={Boolean(item.status)}
                      onChange={(e) => updateKey(item.id, e)}
                    />
                  }
                  labelPlacement="start"
                  label=""
                  sx={{ margin: "0 !important" }}
                />
                <Tooltip
                  title="Delete"
                  onClick={() => deleteKey(item.id)}
                >
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
}
