import { makeStyles } from "@mui/styles";
import { PageContent } from "../../features/DomainsContent";


const useStyles = makeStyles((theme) => ({
  cont: {
    width: "100%",
    marginTop: "20px",
  },
}));

function Domains({ setSnack }) {
  const classes = useStyles();

  return (
    <main className={classes.cont}>
      <PageContent setSnack={setSnack} />
    </main>
  );
}

export default Domains;