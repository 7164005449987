import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers";

export default function CustomDatePicker({ sx, value, callback, disabled }) {
  const handle = (val) => {
    callback(dayjs(val));
  };
  return (
    <LocalizationProvider dateLibInstance={dayjs.tz} dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DemoItem>
          <DatePicker
            disabled={disabled}
            sx={{
              "& .MuiInputBase-input": {
                height: "10px",
              },
              ...sx,
            }}
            defaultValue={dayjs(value)}
            onChange={(newValue) => handle(newValue)}
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
