import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "3",
    width: "100%",
  },
}));

function Header({ children }) {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      {children}
    </header>
  );
}

export default Header;