import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { getDomainDns } from "../../../api/api";
import { getTokenValue } from "../../../config/cookie";
import Modal from "../../../Components/Modal";
import { AddDomainForm } from "./AddDomainsForm";
import GlobalLoader from "../../../Components/GlobalLoader";
import { transformSelectData } from "../../../utils/transformSelectData";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "20px",
  },
  box: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: "12px",
    margin: "0",
    padding: "0",
  },
}));

function ModalAddDomains({ open, close, setSnack }) {
  const classes = useStyles();

  const [dns, setDns] = useState(['']);
  const [loading, setLoading] = useState(false);

  const getDns = async () => {
    const token = getTokenValue();
    setLoading(true);

    try {
      const res = await getDomainDns(token);
      if (res.success) {
        const data = res.data.map(transformSelectData);
        setDns([...data]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch(error) {
      setSnack({
        text: error.message ? error.message : "Error",
        type: "error",
        open: true,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDns();
  }, []);

  if (!open) return null;

  return (
    <Modal open={open} close={close}>
      <div className={classes.box}>
        <h2 className={classes.title}>Add Domains</h2>
        {loading 
          ? <GlobalLoader style={{ width: "100%", height: "200px" }} /> 
          : <AddDomainForm dns={dns} setSnack={setSnack} close={close} />
        }
      </div>
    
    </Modal>
  );
}

export { ModalAddDomains };