import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import {
  fetchFiltersAffiliates,
  fetchHold,
  fetchLead,
  fetchLeadLogs,
} from "../../api/api";
import { getTokenValue } from "../../config/cookie";
import { profileParams } from "../../config/profileParams";
import LeadCard from "../../Components/LeadCard";
import { Box, Button, Typography } from "@mui/material";
import GlobalLoader from "../../Components/GlobalLoader";
import Icon from "../../png/prIcon.svg";

const useStyles = makeStyles((theme) => ({
  cont: {
    "& h2": { fontSize: "20px", fontWeight: 700 },
    "& .spinner-container": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "300px",
    },
    "& .card-list": {
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gridAutoFlow: "dense",
      gap: "12px",
    },
    "& .card--wide": { gridColumn: "span 2" },
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
  },
}));

export default function Lead({ setSnack }) {
  const classes = useStyles();
  const { id } = useParams();
  const [dataLead, setDataLead] = useState({});
  const [affiliates, setAffiliates] = useState([]);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const copyText = () => {
    navigator.clipboard.writeText(id);
    setSnack({
      text: "Copied",
      type: "info",
      open: true,
    });
  };

  const getLead = async () => {
    try {
      const res = await fetchLead(getTokenValue(), id);
      if (res.success) {
        setDataLead({ ...res.data });
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setLoading(false);
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  };

  const getLeadLogs = async () => {
    try {
      const res = await fetchFiltersAffiliates(getTokenValue());
      if (res.success) {
        setAffiliates([...res.data]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }

      const res2 = await fetchLeadLogs(getTokenValue(), id);
      if (res2.success) {
        setLogs([...res2.data]);
      } else {
        setSnack({
          text: res2.message ? res2.message : "Error",
          type: "error",
          open: true,
        });
      }

      setLoading(false);
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getLead();
  }, []);

  const handleOpen = async () => {
    await getLeadLogs();
    setOpen(true);
  };

  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <>
          <Box className={classes.cont}>
            <Box className={classes.head}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h2">Profile</Typography>
                  <img src={Icon} style={{ marginLeft: "3px" }} />
                </Box>
                <Typography variant="h2"> {dataLead.name}</Typography>
              </Box>
              <Button variant="contained" onClick={handleOpen}>
                Check Logs
              </Button>
            </Box>
            <div className="card-list">
              <LeadCard
                title="Аккаунт"
                data={dataLead}
                params={profileParams.Account}
                copyText={copyText}
              />
              <LeadCard
                title="Контакты"
                data={dataLead}
                params={profileParams.Contacts}
              />
              <LeadCard
                title="Маркетинговая информация"
                data={dataLead}
                params={profileParams.Marketing}
                classes="card--wide"
              />
              <LeadCard
                title="Назначения"
                data={dataLead}
                params={profileParams.Assigments}
              />
              <LeadCard
                title="Дополнительно"
                data={dataLead}
                params={profileParams.Extra}
              />
              <LeadCard
                title="Финансы"
                data={dataLead}
                params={profileParams.Finances}
              />
            </div>
          </Box>
        </>
      )}
    </>
  );
}
