import React from "react";
import Menu from "./Menu";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import GlobalLoader from "./GlobalLoader";
import Header from "./Header";

export default function Layout({
  loading,
  setIsLogin,
  role,
}) {
  const useStyles = makeStyles((theme) => ({
    box: {
      width: "100%",
      padding: "2.5rem 1.5rem 2.25rem",
      backgroundColor: "#e5e7eb",
      overflow: "hidden",
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Header>
        <Menu
          setIsLogin={setIsLogin}
          role={role}
        />
      </Header>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Box className={classes.box}>
          <Outlet />
        </Box>
      )}
    </>
  );
}
