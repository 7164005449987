import { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "./CustomInput";
import { roles } from "../config/roles";

const useStyles = makeStyles((theme) => ({
  cont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "20px",
    width: "600px",
    position: "relative",
    backgroundColor: "#e5e7eb!important",
  },
  box: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    margin: "20px 0px",
    padding: "0px 10px",
  },
  boxItem: {
    width: "45%",
    margin: "7px 0px",
  },
  close: {
    position: "absolute!important",
    top: "10px",
    right: "10px",
  },
  listItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function ModalCreateUser({
  open,
  setOpen,
  data,
  setData,
  callback,
  setSnack,
}) {
  const classes = useStyles();

  const handleData = (value, target) => {
    setData({
      ...data,
      [target]: value,
    })
  }

  const close = () => {
    setOpen(false);
    setData({});
  };

  const create = () => {
    callback();
    close();
  };

  return (
    <Backdrop open={open} sx={{ zIndex: "3" }}>
      <ClickAwayListener onClickAway={close}>
        <Paper className={classes.cont}>
          <IconButton className={classes.close} onClick={close}>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>
            Create User
          </Typography>

          <Box className={classes.box}>
            <Box className={classes.boxItem}>
              <Typography gutterBottom>Name</Typography>
              <CustomInput 
                value={data.name} 
                sx={{ width: "100%" }} 
                callback={(val) => handleData(val, "name")}
              />
            </Box>

            <Box className={classes.boxItem}>
              <Typography gutterBottom>Email</Typography>
              <CustomInput
                value={data.email}
                sx={{ width: "100%" }}
                callback={(val) => handleData(val, "email")}
              />
            </Box>

            <Box className={classes.boxItem}>
              <Typography gutterBottom>Password</Typography>
              <CustomInput
                value={data.password}
                sx={{ width: "100%" }}
                callback={(val) => handleData(val, "password")}
              />
            </Box>

            <Box className={classes.boxItem}>
              <Typography gutterBottom>Role</Typography>
              <select
                style={{width:"100%", minHeight: "1.4375em", padding: "7.5px 14px", backgroundColor: "#F0F0F0", border: "none", borderRadius: "8px", outlineColor: "#4669FA"}}
                value={data.role}
                onChange={(evt) => handleData(evt.target.value, "role")}
              >
                {roles.map(item => (
                  <option value={item.name} key={item.name}>{item.title}</option>
                ))}
              </select>
            </Box>
          </Box>

          <Button variant="contained" sx={{ width: "100%" }} onClick={create}>
            Create
          </Button>
        </Paper>
      </ClickAwayListener>
    </Backdrop>
  );
}
