import * as React from "react";
import { makeStyles } from "@mui/styles";
import CustomTable from "../../Components/CustomTable";
import {
  Box,
  Button,
  IconButton,
  Paper,
  TablePagination,
  Toolbar,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useState } from "react";
import { useEffect } from "react";
import {
  fetchCancelLeads,
  fetchDeleteFilter,
  fetchDeleteLeads,
  fetchFiltersAffiliates,
  fetchFiltersCampaigns,
  fetchFiltersStatuses,
  fetchHold,
  fetchLeadRedirect,
  fetchLeads,
  fetchNewFilter,
  fetchSavedFilters,
  fetchUpdateFilter,
  fetchUsers,
  fetchCountries,
} from "../../api/api";
import { headCells } from "../../config/heads";
import ModalFilters from "../../Components/ModalFilters";
import { useMemo } from "react";
import { constructFilters } from "../../utils/filters";
import { getTokenValue } from "../../config/cookie";
import ModalRedirect from "../../Components/ModalRedirect";
import ModalLead from "../../Components/ModalLead";
import Tools from "../../Components/Tools";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { predefinedRanges } from "../../config/dateRanges";
import CustomSelect from "../../Components/CustomSelect";
import { useRef } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  cont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
  },
  table: {
    padding: " 0 1.25rem",
    backgroundColor: "#FFF",
    borderRadius: "0.375rem",
    boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16)",
  }
}));

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const INITIAL_TIMER = 10;

function EnhancedTableToolbar(props) {
  const { numSelected, setRedirectOpen, deleteLeads, cancelLeads, currentRegion, regions, handleRegion } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 0 },
        pr: { sm: 0 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Box>
          <Button
            variant="contained"
            sx={{ marginRight: "15px" }}
            onClick={cancelLeads}
          >
            Отменить отправку
          </Button>
          <Button variant="contained" onClick={() => setRedirectOpen(true)}>
            Перенаправить
          </Button>
        </Box>
      ) : (
        <>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Результаты
          </Typography>
          <CustomSelect
            value={currentRegion}
            options={regions}
            placeholder={"select region"}
            callback={handleRegion}
            sx={{ backgroundColor: "#FFF" }}
          />
        </>
      )}

      {numSelected > 0 ? (
        <Tooltip
          title="Delete"
          sx={{ marginLeft: "15px" }}
          onClick={deleteLeads}
        >
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

export default function Platform({ setSnack, role }) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [filters, setFilters] = useState([
    {
      name: "",
      type: "",
      value: "",
      compare: false,
    },
  ]);
  const [fetchedFilters, setFetchedFilters] = useState({
    status: [],
    campaign: [],
    affiliate: [],
    user: [],
  });
  const [openFiltred, setOpenFiltred] = useState({
    id: null,
    status: null,
    campaign: null,
    affiliate: null,
    created_at: null,
    user: null,
  });
  const [openFilters, setOpenFilters] = useState(null);
  const [filtersEnabled, setFiltersEnabled] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    JSON.parse(localStorage.getItem("rowsPerPage"))
      ? JSON.parse(localStorage.getItem("rowsPerPage"))
      : 10
  );
  const [openExclude, setOpenExclude] = useState(null);
  const [exclude, setExclude] = useState([]);
  const [total, setTotal] = useState(0);
  const [start, setStart] = useState(0);
  const [redirectOpen, setRedirectOpen] = useState(false);
  const [redirects, setRedirects] = useState([]);

  const [filtersName, setFiltersName] = useState("");
  const [filtersSaved, setFiltersSaved] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [autoReload, setAutoReload] = useState(false);
  const [handleFiltersChange, setHandleFiltersChange] = useState(false);
  const [hold, setHold] = useState("");
  const [timer, setTimer] = useState(INITIAL_TIMER);
  const [newLeads, setNewLeads] = useState([]);
  const interval = useRef();

  const [regions, setRegions] = useState([]);
  const [currentRegion, setCurrentRegion] = useState('');

  const [loaded, setLoaded] = useState(false);

  const [openUserModal, setOpenUserModal] = useState(false);
  const [currentUserId, setCurrentUserId] = useState('');

  function handleTimer() {
    interval.current = setInterval(() => {
      setTimer((count) => count - 1);
    }, 1000);
  }

  useEffect(() => {
    if (timer < INITIAL_TIMER) {
      localStorage.setItem("autoReload", autoReload);
    }
    if (autoReload && !interval.current) {
      handleTimer();
    } else if (!autoReload && interval.current) {
      clearInterval(interval.current);
      interval.current = null;
      setTimer(INITIAL_TIMER);
    }

    if (timer <= 0 && interval.current) {
      getData(true);
      setTimer(INITIAL_TIMER);
    }
  }, [timer, autoReload]);

  const handleAutoReload = () => {
    // if (!autoReload) {
    //   handleTimer();
    // } else {
    //   clearInterval(interval.current);
    // }
    setAutoReload(!autoReload);
  };

  const constructOptionsFilters = (length, from) => {
    const arr = filtersSaved.map((item) => ({
      title: item.name,
      name: item.id,
    }));
    if (length) {
      arr.length = 3;
    } else if (from) {
      arr.splice(0, from);
      arr.unshift({
        title: "Без шаблона",
        name: undefined,
      });
    } else {
      arr.unshift({
        title: "Без шаблона",
        name: undefined,
      });
    }
    return arr;
  };

  const handleDateRangeFilt = (val) => {
    setDateRange(val);
  };

  const handleFiltersName = (val) => {
    setFiltersName(val);
  };

  const getSavedFilters = async () => {
    try {
      const res = await fetchSavedFilters(getTokenValue());
      if (res.success) {
        setFiltersSaved([...res.data]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  };

  const deleteFilter = async () => {
    const data = {
      id: filtersSaved.find((item) => item.name === filtersName).id,
    };
    try {
      const res = await fetchDeleteFilter(getTokenValue(), data);
      if (res.success) {
        setCurrentFilter("");
        handleFiltersName("");
        setFilters([
          {
            name: "",
            type: "",
            value: "",
            compare: false,
          },
        ]);
        getSavedFilters();
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  };

  const handleCurrentFilter = (val, search) => {
    if (val === undefined) {
      setCurrentFilter("");
      handleFiltersName("");
      setFilters([
        {
          name: "",
          type: "",
          value: "",
          compare: false,
        },
      ]);
      return;
    }
    setCurrentFilter(val);
    const _filters = filtersSaved.find((item) => item.id === val);
    if (_filters.date_range) {
      const indexDate = predefinedRanges.findIndex(
        (item) => item.label === _filters.date_range
      );
      _filters.filters.forEach((f) => {
        if (f.name === "created_at" && f.type === "greater") {
          f.value = predefinedRanges[indexDate].value[0];
        }
        if (f.name === "created_at" && f.type === "less") {
          f.value = predefinedRanges[indexDate].value[1];
        }
      });
      setDateRange(_filters.date_range);
    }
    handleFiltersName(_filters.name);
    setFilters([..._filters.filters]);
    setPage(1);
    setStart(0);
    if (search) {
      if (!filtersEnabled) setFiltersEnabled(true);
      else setHandleFiltersChange(!handleFiltersChange);
    }
  };

  const saveNewFilter = async () => {
    const filt = filtersSaved.find((item) => item.name === filtersName);
    if (filt) {
      const data = {
        id: filtersSaved.find((item) => item.name === filtersName).id,
        filters: [...filters],
        date_range: dateRange,
      };
      try {
        const res = await fetchUpdateFilter(getTokenValue(), data);
        if (res.success) {
          getSavedFilters();
        } else {
          setSnack({
            text: res.message ? res.message : "Error",
            type: "error",
            open: true,
          });
        }
      } catch (err) {
        setSnack({
          text: err,
          type: "error",
          open: true,
        });
      }
    } else {
      const data = {
        name: filtersName,
        filters: filters,
        date_range: dateRange,
      };
      try {
        const res = await fetchNewFilter(getTokenValue(), data);
        if (res.success) {
          getSavedFilters();
        } else {
          setSnack({
            text: res.message ? res.message : "Error",
            type: "error",
            open: true,
          });
        }
      } catch (err) {
        setSnack({
          text: err,
          type: "error",
          open: true,
        });
      }
    }
  };

  const handleRegion = (val) => {
    setCurrentRegion(val);
    localStorage.setItem("currentRegion", val);
  };

  const getCountries = async () => {
    try {
      const res = await fetchCountries(getTokenValue());
      if (res.success) {
        const transformedData = res.data.map(item => ({
          id: item.id,
          name: item.name,
          title: item.name,
        }));

        setRegions([...transformedData]);
        setCurrentRegion(localStorage.getItem("currentRegion")
          ? localStorage.getItem("currentRegion")
          : transformedData[0].name);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  }

  const init = async()=>{
    await getCountries();
    getFilters();
    getSavedFilters();
    getHold();
    const _exclude = JSON.parse(localStorage.getItem("exclude"));
    if (_exclude) {
      setExclude([..._exclude]);
    } else {
      setExclude([
        "custom_param1",
        "custom_param2",
        "custom_param3",
        "custom_param4",
        "custom_param5",
        "custom_param6",
      ]);
    }

    const _selected = JSON.parse(localStorage.getItem("selected"));
    if (_selected) {
      setSelected([..._selected]);
    }

    const _filters = JSON.parse(localStorage.getItem("filters"));
    if (_filters) {
      const fixFilters = _filters.map(item=>{
        if(item.name === "created_at" || item.name === "send_date"){
          return {
            ...item,
            value: dayjs(item.value)["$d"]
          }
        }
        else return item;
      })
      setFilters([...fixFilters]);
    }

    const _filtersEnabled = JSON.parse(localStorage.getItem("filtersEnabled"));
    setFiltersEnabled(_filtersEnabled);

    const _autoReload = JSON.parse(localStorage.getItem("autoReload"));
    setAutoReload(_autoReload);

    const _newLeads =
      localStorage.getItem("newLeads") &&
      localStorage.getItem("newLeads") !== null
        ? JSON.parse(localStorage.getItem("newLeads"))
        : [];
    if (_newLeads.length > 0) {
      setNewLeads(_newLeads);
    }
    setLoaded(true);
  }

  useEffect(() => {
    if(!loaded) return;
    getData();
    localStorage.setItem("filtersEnabled", filtersEnabled);
  }, [start, rowsPerPage, filtersEnabled, order, orderBy, handleFiltersChange, currentRegion]);

  useEffect(() => {
    if(!loaded) return;
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    init();
  }, []);

  const filtersHandleGetData = () => {
    filtersEnabled ? getData() : setFiltersEnabled(true);
    setPage(1);
    setStart(0);
  };

  const getData = async (notLoader) => {
    if (!notLoader) setLoading(true);
    const _filters = filters.map((item) => {
      if (item.name === "phone") {
        return { ...item, value: item.value.replace("+", "%2B") };
      } else return item;
    });
    const cFilters = constructFilters(_filters);
    const regionId = regions?.find(item => item.name === currentRegion)?.id;
    const params = `start=${start}&length=${rowsPerPage}${
      filtersEnabled ? "&filters=" + JSON.stringify(cFilters) : ""
    }&order_by=["${orderBy}","${order}"]&country_id=${regionId}`;
    try {
      const res = await fetchLeads(getTokenValue(), params);
      if (res.success) {
        const arr = [...res.data];

        if (autoReload) {
          const lastID = JSON.parse(localStorage.getItem("lastID"));

          if (lastID > 0) {
            const _newLeads = [...newLeads];
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].id > lastID) _newLeads.push(arr[i].id);
            }
            setNewLeads([..._newLeads]);
          }

          localStorage.setItem("lastID", order === "desc" && res.data[0]?.id ? res.data[0]?.id : 0);
        }

        setData([...arr]);
        res.total ? setTotal(res.total) : null;
        getHold();
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setLoading(false);
    } catch (err) {
      setSnack({
        text: JSON.stringify(err.message),
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  };

  const handleNewLead = () => {
    if (!newLeads.length) return;
    setNewLeads([]);
  };

  useEffect(() => {
    localStorage.setItem("newLeads", JSON.stringify([...newLeads]));
  }, [newLeads]);

  const getFilters = async () => {
    const arrs = {
      status: [],
      campaign: [],
    };
    try {
      const res1 = await fetchFiltersStatuses(getTokenValue());
      if (res1.success) {
        arrs.status = [...res1.data];
      } else {
        setSnack({
          text: res1.message ? res1.message : "Error",
          type: "error",
          open: true,
        });
      }

      const res2 = await fetchFiltersCampaigns(getTokenValue());
      if (res2.success) {
        arrs.campaign = [...res2.data];
      } else {
        setSnack({
          text: res2.message ? res2.message : "Error",
          type: "error",
          open: true,
        });
      }

      const res3 = await fetchFiltersAffiliates(getTokenValue());
      if (res3.success) {
        arrs.affiliate = [...res3.data];
      } else {
        setSnack({
          text: res3.message ? res3.message : "Error",
          type: "error",
          open: true,
        });
      }

      const res4 = await fetchUsers(getTokenValue());
      if (res4.success) {
        arrs.user = [...res4.data.users];
      } else {
        setSnack({
          text: res4.message ? res4.message : "Error",
          type: "error",
          open: true,
        });
      }
      setFetchedFilters({
        ...arrs,
      });
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  };

  const redirect = async () => {
    try {
      const res = await fetchLeadRedirect(getTokenValue(), {
        ids: selected,
        affiliate_id: redirects[0],
      });
      if (res.success) {
        getData();
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setLoading(false);
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  };

  const getHold = async () => {
    try {
      const index = filters.findLastIndex(item=>item.name === "user");
      const id = index !== -1 && filtersEnabled ? filters[index].value : undefined;
      const regionId = regions?.find(item => item.name === currentRegion)?.id;
      const res = await fetchHold(getTokenValue(), { id, country_id: regionId });
      if (res.success) {
        setHold(res.data.hold);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    const oldPage = page;
    setPage(newPage);
    const newStart = start + (newPage - oldPage) * rowsPerPage;
    setStart(newStart < 0 ? 0 : newStart);
  };

  const handleChangeRowsPerPage = (event) => {
    const oldRowsPerPage = rowsPerPage;
    const newRowsPerPage = event.target.value;
    const _newRowsPerPage = parseInt(newRowsPerPage, 10);
    setRowsPerPage(_newRowsPerPage);
    localStorage.setItem("rowsPerPage", _newRowsPerPage);
    const newPage = start === 0 ? 1 : Math.ceil(start / newRowsPerPage) + 1;
    setPage(newPage);
  };

  const handleOpenExclude = (e) => {
    if (openExclude) {
      setOpenExclude(null);
    } else {
      setOpenExclude(e.currentTarget);
    }
  };

  const handleOpenFiltred = (e, target) => {
    if (openFiltred[target]) {
      setOpenFiltred({ ...openFiltred, [target]: null });
    } else {
      setOpenFiltred({ ...openFiltred, [target]: e.currentTarget });
    }
  };

  const handleOpenFilters = (e) => {
    if (openFilters) {
      setOpenFilters(false);
    } else {
      setOpenFilters(true);
    }
  };

  const handleFiltersEnabled = (e) => {
    if (filtersEnabled) {
      setFiltersEnabled(false);
    } else {
      setFiltersEnabled(true);
    }
  };

  const handleExclude = (target) => {
    const index = exclude.findIndex((exc) => exc === target);
    const arr = [...exclude];
    if (index === -1) arr.push(target);
    else {
      arr.splice(index, 1);
    }
    localStorage.setItem("exclude", JSON.stringify([...arr]));
    setExclude([...arr]);
  };

  const handleFiltred = (target, value, compare) => {
    const arr = [...filters];

    const index = filters.findIndex(
      (f) => f.name === target && f.value === value
    );

    if (
      target === "id" ||
      target === "name" ||
      target === "email" ||
      target === "source" ||
      target === "phone"
    ) {
      const indexId = filters.findIndex((f) => f.name === target);
      if (indexId === -1) {
        if (arr.length === 1 && !arr[0].name) {
          arr[0] = {
            name: target,
            type: compare ? "contain" : "equal",
            value: value,
            compare: false,
          };
        } else {
          arr.push({
            name: target,
            type: compare ? "contain" : "equal",
            value: value,
            compare: false,
          });
        }
      } else {
        arr[indexId].value = value;
        arr[indexId].type = compare ? "contain" : "equal";
      }
    } else if (target === "created_at" || target === "send_date") {
      const otTarget = target === "created_at" ? "send_date" : "created_at";
      let otTargetId = 1111111111;
      while (otTargetId !== -1) {
        otTargetId = arr.findIndex((f) => f.name === otTarget);
        if (otTargetId !== -1) {
          arr.splice(otTargetId, 1);
        }
      }
      const dateStartId = arr.findIndex(
        (f) => f.name === target && f.type === "greater"
      );
      const dateEndId = arr.findIndex(
        (f) => f.name === target && f.type === "less"
      );
      if (dateStartId === -1 && dateEndId === -1) {
        if (arr.length === 1 && !arr[0].name) {
          arr[0] = {
            name: target,
            type: "greater",
            value: value[0],
            compare: false,
          };
          arr[1] = {
            name: target,
            type: "less",
            value: value[1],
            compare: false,
          };
        } else {
          arr.push({
            name: target,
            type: "greater",
            value: value[0],
            compare: false,
          });
          arr.push({
            name: target,
            type: "less",
            value: value[1],
            compare: false,
          });
        }
      } else if (dateStartId !== -1 && dateEndId !== -1) {
        arr[dateStartId].value = value[0];
        arr[dateEndId].value = value[1];
      } else if (dateStartId !== -1 && dateEndId === -1) {
        arr[dateStartId].value = value[0];
        arr.splice(dateStartId + 1, 0, {
          name: target,
          type: "less",
          value: value[1],
          compare: false,
        });
      } else if (dateStartId === -1 && dateEndId !== -1) {
        arr[dateEndId].value = value[1];
        arr.splice(dateEndId, 0, {
          name: target,
          type: "greater",
          value: value[0],
          compare: false,
        });
      }
    } else if (
      target === "status" ||
      target === "campaign" ||
      target === "affiliate" ||
      target === "user"
    ) {
      if (index === -1) {
        if (arr.length === 1 && !arr[0].name) {
          arr[0] = {
            name: target,
            type: "equal",
            value: value,
            compare: true,
          };
        } else {
          const indexTarget = arr.findLastIndex((item) => item.name === target);
          if (indexTarget === -1) {
            arr.push({
              name: target,
              type: "equal",
              value: value,
              compare: false,
            });
            if (arr[arr.length - 2]) {
              arr[arr.length - 2].compare = false;
            }
          } else {
            arr.splice(indexTarget + 1, 0, {
              name: target,
              type: "equal",
              value: value,
              compare: false,
            });
            if (arr[indexTarget]) arr[indexTarget].compare = true;
          }
        }
      } else {
        arr.splice(index, 1);
      }
    }

    setFilters([...arr]);
  };

  const resetFiltred = (target) => {
    const arr = filters.filter((item) => item.name !== target);
    setFilters([...arr]);
  };

  const closeFiltred = (target) => {
    handleOpenFiltred(null, target);

    if ((filters.length === 1 && !filters[0].name) || filters.length === 0) {
      setFiltersEnabled(false);
      return;
    }

    if (!filtersEnabled) setFiltersEnabled(true);
    else getData();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const cancelLeads = async () => {
    try {
      setLoading(true);
      const res = await fetchCancelLeads(getTokenValue(), { ids: selected });
      if (res.success) {
        setSelected([]);
        getData();
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setLoading(false);
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  };

  const deleteLeads = async () => {
    try {
      setLoading(true);
      const res = await fetchDeleteLeads(getTokenValue(), { ids: selected });
      if (res.success) {
        setSelected([]);
        getData();
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setLoading(false);
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  };

  const exportToCSV = () => {
    if (!data.length || loading) return;
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataA = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataA, "Leads" + fileExtension);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      localStorage.setItem("selected", JSON.stringify([...newSelected]));
      setSelected([...newSelected]);
      return;
    }
    localStorage.setItem("selected", JSON.stringify([]));
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected([...newSelected]);
    localStorage.setItem("selected", JSON.stringify([...newSelected]));
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const resetFilters = () => {
    setFilters([
      {
        name: "",
        type: "",
        value: "",
        compare: false,
      },
    ]);
    setFiltersEnabled(false);
  }

  const handleOnUserClick = (userId) => {
    setCurrentUserId(userId);
    setOpenUserModal(true);
  }

  const handleReload = () => {
    getData();
  }

  return (
    <>
      {useMemo(() =>
        openFilters ? (
          <ModalFilters
            filters={filters}
            setFilters={setFilters}
            open={openFilters}
            close={handleOpenFilters}
            callback={filtersHandleGetData}
            enabled={filtersEnabled}
            handleEnabled={handleFiltersEnabled}
            fetchedFilters={fetchedFilters}
            setSnack={setSnack}
            exclude={exclude}
            filtersName={filtersName}
            filtersSaved={filtersSaved}
            currentFilter={currentFilter}
            handleFiltersName={handleFiltersName}
            deleteFilter={deleteFilter}
            handleCurrentFilter={handleCurrentFilter}
            saveNewFilter={saveNewFilter}
            handleDateRangeFilt={handleDateRangeFilt}
            dateRange={dateRange}
            constructOptionsFilters={constructOptionsFilters}
          />
        ) : null,
        [
          filters,
          openFilters,
          filtersEnabled,
          fetchedFilters,
          exclude,
          filtersName,
          filtersSaved,
          currentFilter,
          dateRange,
        ]
      )}
      {redirectOpen ? (
        <ModalRedirect
          affiliates={fetchedFilters.affiliate}
          open={redirectOpen}
          setOpen={setRedirectOpen}
          data={redirects}
          setData={setRedirects}
          callback={redirect}
        />
      ) : null}
      {openUserModal ? (
        <ModalLead open={openUserModal} setOpen={setOpenUserModal} id={currentUserId} setSnack={setSnack} />
      ) : null}
      <Box className={classes.cont} >
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{
              width: "100%",
              mb: 2,
              boxShadow: "none",
              backgroundColor: "#e5e7eb",
              marginBottom:"14px"
            }}
          >
            <Tools 
              openExclude={openExclude}
              handleOpenExclude={handleOpenExclude} 
              exclude={exclude}
              handleExclude={handleExclude}
              handleOpenFilters={handleOpenFilters} 
              exportToCSV={exportToCSV}
              constructOptionsFilters={constructOptionsFilters}
              filtersSaved={filtersSaved}
              autoReload={autoReload}
              handleAutoReload={handleAutoReload}
              hold={hold}
              currentFilter={currentFilter}
              handleCurrentFilter={handleCurrentFilter}
              handleReload={handleReload}
              resetFilters={resetFilters}
            />
            <EnhancedTableToolbar
              numSelected={selected.length}
              setRedirectOpen={setRedirectOpen}
              deleteLeads={deleteLeads}
              cancelLeads={cancelLeads}
              currentRegion={currentRegion}
              regions={regions}
              handleRegion={handleRegion}
            />
            <div className={classes.table}>
              <TablePagination
                rowsPerPageOptions={[10, 20, 100, 500]}
                showFirstButton
                showLastButton
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                page={page - 1}
                labelDisplayedRows={() =>
                  total - start > rowsPerPage
                  ? `${start + 1}-${start + rowsPerPage} of ${total}`
                  : `${start + 1}-${total} of ${total}`
                }
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={() => {
                  return (
                    <Pagination
                      onChange={handleChangePage}
                      page={page}
                      count={Math.ceil(total / rowsPerPage)}
                      sx={{ minWidth: "375px" }}
                    />
                  );
                }}
              />

              {useMemo(
                () => (
                  <CustomTable
                    data={data}
                    heads={headCells}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    selected={selected}
                    setSelected={setSelected}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    exclude={exclude}
                    fetchedFilters={fetchedFilters}
                    filters={filters}
                    openFiltred={openFiltred}
                    handleOpenFiltred={handleOpenFiltred}
                    handleFiltred={handleFiltred}
                    closeFiltred={closeFiltred}
                    resetFiltred={resetFiltred}
                    setRedirectOpen={setRedirectOpen}
                    deleteLeads={deleteLeads}
                    cancelLeads={cancelLeads}
                    role={role}
                    handleSelectAllClick={handleSelectAllClick}
                    isSelected={isSelected}
                    handleClick={handleClick}
                    handleNewLead={handleNewLead}
                    newLeads={newLeads}
                    loading={loading}
                    dataLength={data.length}
                    resetFilters={resetFilters}
                    handleOnUserClick={handleOnUserClick}
                  />
                ),
                [
                  data,
                  order,
                  orderBy,
                  selected,
                  exclude,
                  filters,
                  openFiltred,
                  fetchedFilters,
                  loading,
                  role,
                  newLeads,
                ]
              )}
            </div>
          </Paper>
        </Box>
      </Box>
    </>
  );
}
