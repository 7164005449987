import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#4669FA',
    },
    secondary: {
      main: 'rgb(71 85 105 / 1)',
    },
    error: {
      main: red.A400,
    },
    menu: {
      main: "#FFF"
    }
  },
  components: {
    // Name of the component 
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: "10px",
          textTransform: "none",
          boxShadow: "none",
          "& svg": {
            marginLeft: "3px"
          }
        },
        contained: {
          color: "white"
        }
      },
      variants: [
        {
          props: { variant: 'reset' },
          style: {
            backgroundColor: "#AAB1AF",
            color: "white",
            "&:hover": {
              backgroundColor: "#898F8D",
            }
          },
        }
      ],
    },
    MuiSelect: {
      styleOverrides: {
        // Name of the slot 
        root: {
          // Some CSS
          borderRadius: "10px",
        }
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
        notchedOutline: {
          // border: "none",
          borderWidth: "0px"
        }
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          backgroundColor: "#F0F0F0",
        }
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "15px",

        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#e5e7eb"
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#9E9E9E"
        }
      }
    }
  },
});

export default theme;
