import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  head: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
    "& h2": {
      margin: "0",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "20px",
    }
  },
}));

function PageHead({ title, children }) {
  const classes = useStyles();

  return (
    <header className={classes.head}>
      <h2>{title}</h2>
      {children}
    </header>
  );
}

export default PageHead;