import { Backdrop, ClickAwayListener, IconButton, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  cont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "20px",
    width: "1000px",
    maxHeight: "100%",
    position: "relative",
    overflow: "auto",
  },
  close: {
    position: "absolute!important",
    top: "10px",
    right: "10px",
  },
}));

function Modal({ open, close, children }) {
  const classes = useStyles();

  const closeAway = (e) => {
    const arr = e.target.className.split(" ");
    if (
      arr.indexOf("MuiBackdrop-root") !== -1 &&
      arr.indexOf("MuiBackdrop-invisible") === -1
    ) {
      close();
    }
  };
  
  return (
    <Backdrop open={open} sx={{ zIndex: "10" }}>
      <ClickAwayListener onClickAway={closeAway} disableReactTree>
        <Paper className={classes.cont}>
          <IconButton className={classes.close} onClick={close}>
            <CloseIcon /> 
          </IconButton>
          {children}
        </Paper>
      </ClickAwayListener>
    </Backdrop>
  );
}

export default Modal;