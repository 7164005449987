import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export default function ({ callback, exclude }) {
  const isExcluded = (name) =>
    exclude.findIndex((item) => item === name) === -1;
  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("id")}
              onChange={() => callback("id")}
            />
          }
          label="ID"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("country")}
              onChange={() => callback("country")}
            />
          }
          label="Страна"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("name")}
              onChange={() => callback("name")}
            />
          }
          label="Имя"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("email")}
              onChange={() => callback("email")}
            />
          }
          label="Email"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("phone")}
              onChange={() => callback("phone")}
            />
          }
          label="Телефон"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("status")}
              onChange={() => callback("status")}
            />
          }
          label="Статус"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("campaign")}
              onChange={() => callback("campaign")}
            />
          }
          label="Кампейн"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("affiliate")}
              onChange={() => callback("affiliate")}
            />
          }
          label="Покупатель"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("landing")}
              onChange={() => callback("landing")}
            />
          }
          label="Лендинг"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("timezone")}
              onChange={() => callback("timezone")}
            />
          }
          label="Время"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("desc")}
              onChange={() => callback("desc")}
            />
          }
          label="Описание"
        />
          <FormControlLabel
              control={
                  <Checkbox
                      checked={isExcluded("comment")}
                      onChange={() => callback("comment")}
                  />
              }
              label="Комментарий"
          />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("source")}
              onChange={() => callback("source")}
            />
          }
          label="Источник"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("user")}
              onChange={() => callback("user")}
            />
          }
          label="Пользователь"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("created_at")}
              onChange={() => callback("created_at")}
            />
          }
          label="Дата"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("send_date")}
              onChange={() => callback("send_date")}
            />
          }
          label="Дата отправки"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("custom_param1")}
              onChange={() => callback("custom_param1")}
            />
          }
          label="Custom 1"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("custom_param2")}
              onChange={() => callback("custom_param2")}
            />
          }
          label="Custom 2"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("custom_param3")}
              onChange={() => callback("custom_param3")}
            />
          }
          label="Custom 3"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("custom_param4")}
              onChange={() => callback("custom_param4")}
            />
          }
          label="Custom 4"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("custom_param5")}
              onChange={() => callback("custom_param5")}
            />
          }
          label="Custom 5"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExcluded("custom_param6")}
              onChange={() => callback("custom_param6")}
            />
          }
          label="Custom 6"
        />
      </FormGroup>
    </>
  );
}
