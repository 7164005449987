import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import CustomInputDate from "./CustomInputDate";
import CustomDatePicker from "./CustomDatePicker";
import SearchIcon from "@mui/icons-material/Search";
import CustomInput from "./CustomInput";

export default function ({ callback, filters, target, reset, close }) {
  const [state, setState] = useState("");
  const [compare, setCompare] = useState(false);

  useEffect(() => {
    const index = filters.findLastIndex((item) => item.name === target);
    if (index !== -1 && filters[index].value !== "") {
      setState(filters[index].value);
      if (filters[index].type === "contain") setCompare(true);
      else setCompare(false);
    }
  }, [filters]);

  const search = (val) => {
    if (!val) return;
    callback(val, compare);
  };

  useEffect(() => {
    search(state);
  }, [compare]);

  const resetData = ()=>{
    reset();
    setState("");
  }

  return (
    <>
      <FormGroup
        sx={{
          height: "110px",
          width: "440px",
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden",
          flexWrap: "nowrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <CustomInput
            value={state}
            callback={(val) => setState(val)}
            blur={(val) => search(val)}
            placeholder={target}
          />
          <FormControlLabel
            control={
              <Switch checked={compare} onChange={() => setCompare(!compare)} />
            }
            labelPlacement="start"
            label="Равно / Содержит"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button variant="reset" sx={{ marginRight: "10px" }} onClick={resetData}>
            Reset
          </Button>
          <Button variant="contained" onClick={close}>
            Search <SearchIcon />
          </Button>
        </Box>
      </FormGroup>
    </>
  );
}
