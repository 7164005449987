import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "./CustomInput";
import CustomInputDate from "./CustomInputDate";

const useStyles = makeStyles((theme) => ({
  cont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "20px",
    width: "600px",
    position: "relative",
    backgroundColor: "#e5e7eb!important",
  },
  box: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    margin: "20px 0px",
  },
  boxItem: {
    width: "45%",
    margin: "7px 0px",
  },
  close: {
    position: "absolute!important",
    top: "10px",
    right: "10px",
  },
  listItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function ModalRedirect({
  open,
  setOpen,
  data,
  setData,
  affiliates,
  callback,
  setSnack,
}) {
  const classes = useStyles();

  const [search, setSearch] = useState("");
  const [affiliatesSearch, setAffiliatesSearch] = useState([]);
  useEffect(() => {
    if (!affiliates?.length) return;
    setAffiliatesSearch([...affiliates]);
  }, [affiliates]);

  const close = () => {
    setOpen(false);
    setData([]);
  };

  const handleSearch = (val) => {
    setSearch(val);
    if (!val) {
      setAffiliatesSearch([...affiliates]);
      return;
    }

    const arr = affiliates.filter((item) => {
      let name = item.name;
      name = name.split("");
      name.length = val.length;
      name = name.join("");
      return name.toLowerCase() === val.toLowerCase();
    });

    setAffiliatesSearch([...arr]);
  };

  const handleData = (id) => {
    const _data = [...data];
    const index = data?.indexOf(id);
    if (index === -1 && _data?.length > 0) return;
    if (index === -1) {
      _data.push(id);
    } else {
      _data.splice(index, 1);
    }
    setData([..._data]);
  };

  const isAffiliated = (id) => {
    if (!data?.length) return false;
    const index = data?.indexOf(id);
    return index !== -1;
  };

  const save = () => {
    callback();
    close();
  };

  return (
    <Backdrop open={open} sx={{ zIndex: "3" }}>
      <ClickAwayListener onClickAway={close}>
        <Paper className={classes.cont}>
          <IconButton className={classes.close} onClick={close}>
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ fontSize: "20px", fontWeight: "700", marginBottom: "20px" }}
          >
            Redirect Affiliate
          </Typography>

          <CustomInput
            placeholder={"Search"}
            callback={handleSearch}
            value={search}
            sx={{ width: "100%" }}
          />
          <Box
            className={classes.box}
            sx={{ height: "300px", overflowY: "auto", marginTop: "5px" }}
          >
            <FormGroup sx={{ width: "100%", paddingRight: "20px" }}>
              {affiliatesSearch?.map((item, i) => (
                <Box className={classes.listItem}>
                  <FormControlLabel
                    key={`affiliatesList${item.id}${i}`}
                    control={
                      <Checkbox
                        checked={isAffiliated(item.id)}
                        onChange={() => handleData(item.id)}
                        disabled={!isAffiliated(item.id) && data?.length > 0}
                      />
                    }
                    label={item.name}
                  />
                  <Typography>ID: {item.id}</Typography>
                </Box>
              ))}
            </FormGroup>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button variant="contained" sx={{ width: "40%" }} onClick={close}>
              Отмена
            </Button>
            <Button variant="contained" sx={{ width: "40%" }} onClick={save}>
              Отправить
            </Button>
          </Box>
        </Paper>
      </ClickAwayListener>
    </Backdrop>
  );
}
