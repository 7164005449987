import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { statsHeadCells } from "../config/heads";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
  row: {
    "&:last-child td, &:last-child th": { border: 0 },
    backgroundColor: "#FFF",
  },
  footer: {
    padding: "10px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFF",
    cursor: "pointer",
  },
}));

export default function TableStats({ data, handleLength, target, length }) {
  const classes = useStyles();

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow:
            "0px 0px 1px -1px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
          backgroundColor: "#e5e7eb",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {statsHeadCells.map((item, i) => (
                <TableCell align={i === 0 ? "left" : "right"} key={i}>
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => (
              <TableRow key={`table${i}`} className={classes.row}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.all_lead}</TableCell>
                <TableCell align="right">{row.clear_lead}</TableCell>
                <TableCell align="right">{row.total_ftd}</TableCell>
                <TableCell align="right">{row.current_ftd}</TableCell>
                <TableCell align="right">
                  {row.ccr ? row.ccr + "%" : 0 + "%"}
                </TableCell>
                <TableCell align="right">{row.new_ftd}</TableCell>
                <TableCell align="right">
                  {row.fcr ? row.fcr + "%" : 0 + "%"}
                </TableCell>
                <TableCell align="right">
                  {row.cr ? row.cr + "%" : 0 + "%"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {length > 5 ? (
          <Box
            className={classes.footer}
            onClick={() => handleLength(target, false)}
            sx={{ marginBottom: "5px" }}
          >
            View Less <KeyboardArrowUpIcon />
          </Box>
        ) : null}
        <Box
          className={classes.footer}
          onClick={() => handleLength(target, true)}
        >
          View More <KeyboardArrowDownIcon />
        </Box>
      </TableContainer>
    </>
  );
}
