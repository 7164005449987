import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { fetchCheckDomains, fetchBuyDomains } from "../../../api/api";
import { getTokenValue } from "../../../config/cookie";
import CustomInput from "../../../Components/CustomInput";
import CustomSelect from "../../../Components/CustomSelect";
import { validateDomainsSuffix } from "../utils/validateDomainsSuffix";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: "16px",
  },
  box: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    margin: "24px 0",
    position: "relative",
  },
  boxItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    margin: "0",
    "& .domain-name": {
      flexShrink: "0",
      fontSize: "0.75rem",
    }
  },
  buttons: {
    display: "flex",
    gap: "16px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

function AddDomainForm({ dns, setSnack, close }) {
  const classes = useStyles();

  const [domains, setDomains] = useState(['']);
  const [errorDomains, setErrorDomains] = useState([]);
  const [currentDns, setCurrentDns] = useState('');
  const [isCorrectDomains, setIsCorrectDomains] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [checkIsLoading, setCheckIsLoading] = useState(false);
  const [buyIsLoading, setBuyIsLoading] = useState(false);

  const addDomain = () => {
    setIsCorrectDomains(false);

    const _domains = [...domains];
    _domains.push('');
    setDomains([..._domains]);
  }

  const clearAll = () => {
    setIsCorrectDomains(false);
    setDomains(['']);
  };

  const deleteDomain = (index) => {
    const _domains = [...domains];
    const el = _domains.splice(index, 1);
    setDomains([..._domains]);

    const _errorDomains = [...errorDomains];
    const _errorDomainIndex = _errorDomains.indexOf(el[0]);
    _errorDomains.splice(_errorDomainIndex, 1);
    setErrorDomains([..._errorDomains]);
  };

  const handleCurrentDns = (value) => {
    setCurrentDns(value);
  }
  
  const handleDomainInput = (index, val) => {
    setIsCorrectDomains(false);

    const _domains = [...domains];
    _domains[index] = val;
    setDomains([..._domains]);
  };

  const handleCheck = () => {
    const isValid = validateDomainsSuffix(domains);
    if (!isValid) {
      setSnack({
        text: 'Домены .ru и .рф должны покупаться отдельно от остальных',
        type: "error",
        open: true,
      })
    } else {
      checkDomains(domains);
    }

    setIsCorrectDomains(isValid);
  }

  const checkDomains = async (domains) => {
    const token = getTokenValue();
    setCheckIsLoading(true);
    try {
      const res = await fetchCheckDomains(token, { domains });
      if (res.success) {
        const invalidDomains = res.data.filter((item) => item.error).map(item => item.domain);
        console.log(invalidDomains);
        if (invalidDomains.length) {
          setErrorDomains([...invalidDomains]);
          setSnack({
            text: `${invalidDomains.join(', ')} already exists, use whois service`,
            type: "error",
            open: true,
          });
          return;
        }

        setSnack({
          text: res.message ? res.message : "Success",
          type: "success",
          open: true,
        });
        setIsChecked(true);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
        setIsChecked(false);
      }
    } catch(error) {
      setSnack({
        text: error.message ? error.message : "Error",
        type: "error",
        open: true,
      });
      setIsChecked(false);
    } finally {
      setCheckIsLoading(false);
    }
  }

  const buyDomains = async (domains, currentDns) => {
    const dnsId = dns?.find(item => item.title === currentDns)?.id;
    const token = getTokenValue();
    setBuyIsLoading(true);
    try {
      const res = await fetchBuyDomains(token, { domains, dns_id: String(dnsId) });
      if (res.success) {
        setSnack({
          text: res.message ? res.message : "Success",
          type: "success",
          open: true,
        });
        setDomains(['']);
        close();
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch(error) {
      setSnack({
        text: error.message ? error.message : "Error",
        type: "error",
        open: true,
      });
    } finally {
      setBuyIsLoading(false);
    }
  }

  return (
    <form className={classes.form} action="#">
      <div className={classes.buttons}>
        <label>
          <span style={{ paddingRight: "8px" }}>Привязать к</span>
          <CustomSelect
            options={dns}
            value={currentDns}
            callback={handleCurrentDns}
            sx={{ width: "200px" }}
            placeholder={"Выбрать значение"}
            disabled={buyIsLoading}
          />
        </label>
      </div>
      <div className={classes.box}>
        {domains.map((domain, index) => (
          <Box className={classes.boxItem} key={index}>
            <p className="domain-name">Domain name</p>
            <CustomInput 
              value={domain} 
              sx={{ width: "100%", maxWidth: "300px", border: `${errorDomains.includes(domain) && '1px solid rgb(241, 89, 92)'}` }} 
              callback={(val) => handleDomainInput(index, val)}
              disabled={buyIsLoading}
            />
            <IconButton onClick={() => deleteDomain(index)} disabled={buyIsLoading}>
              <CloseIcon
                sx={{
                  width: "0.6em",
                  height: "0.6em",
                }}
              />
            </IconButton>
            {errorDomains.includes(domain) && 
            <p style={{ color: "rgb(241, 89, 92)" }}>Domain already exists</p>}
          </Box>
        ))}
      </div>
      <div className={classes.footer}>
        <div className={classes.buttons}>
          <Button variant="contained" onClick={addDomain} disabled={buyIsLoading}>
            Добавить домен
            <AddIcon />
          </Button>
          <Button
            variant="outlined"
            color="error"
            sx={{
              color: "rgb(241, 89, 92)",
              borderColor: "rgb(241, 89, 92)",
            }}
            onClick={clearAll}
          >
            Очистить все
          </Button>
        </div>
        <div className={classes.buttons}>
          <Button variant="contained" onClick={handleCheck} disabled={checkIsLoading || buyIsLoading}>
            Проверить домены
          </Button>
          <Button 
            variant="contained" 
            onClick={() => buyDomains(domains, currentDns)} 
            disabled={
              checkIsLoading || !isChecked || !isCorrectDomains || !currentDns || buyIsLoading
              || errorDomains.length
            }
          >
            Купить домены
          </Button>
        </div>
      </div>
    </form>
  );
}

export { AddDomainForm };