import React, { useEffect } from "react";
import { Box, Button, FormControlLabel, IconButton, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import CustomSelect from "./CustomSelect";
import { filterNames, filterSettings } from "../config/filters";
import CustomInput from "./CustomInput";
import CloseIcon from "@mui/icons-material/Close";
import CustomSeachSelect from "./CustomSeachSelect";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomDatePicker from "./CustomDatePicker";
import { predefinedRanges } from "../config/dateRanges";
import Modal from "./Modal";

const useStyles = makeStyles((theme) => ({
  head: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    marginBottom: "15px",
  },
  box: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    margin: "10px 0px",
    position: "relative",
  },
  bottom: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "45px",
  },
}));

export default function ModalFilters({
  open,
  close,
  callback,
  filters,
  setFilters,
  enabled,
  handleEnabled,
  fetchedFilters,
  exclude,
  filtersName,
  filtersSaved,
  currentFilter,
  handleFiltersName,
  deleteFilter,
  handleCurrentFilter,
  saveNewFilter,
  handleDateRangeFilt,
  dateRange,
  constructOptionsFilters,
}) {
  const classes = useStyles();

  const [fetchedFiltersRe, setFetchedFiltersRe] = useState([]);
  const [filterNamesExcluded, setFilterNamesExcleded] = useState(filterNames);

  useEffect(() => {
    if (!fetchedFilters) return;
    const _fetchedFilters = {};

    Object.keys(fetchedFilters).map((nameFilt) => {
      _fetchedFilters[nameFilt] = fetchedFilters[nameFilt].map((item) => ({
        name: item.id,
        title: item.name,
      }));
    });

    setFetchedFiltersRe({ ..._fetchedFilters });
  }, [fetchedFilters]);

  const handleFilters = (target, index, val) => {
    const arrF = [...filters];
    if (target === "name") {
      arrF[index] = {
        name: val,
        type: "",
        value: "",
        compare: false,
      };
    }
    arrF[index][target] = val;
    setFilters([...arrF]);
  };

  const addFilter = () => {
    const arrF = [...filters];
    arrF.push({
      name: "",
      type: "",
      value: "",
      compare: false,
    });
    setFilters([...arrF]);
  };

  const clearAll = () => {
    setFilters([
      {
        name: "",
        type: "",
        value: "",
        compare: false,
      },
    ]);
  };

  const deleteF = (index) => {
    const arrF = [...filters];
    arrF.splice(index, 1);
    setFilters([...arrF]);
  };

  const find = () => {
    callback();
    close();
  };

  const handleCompare = (index) => {
    const arrF = [...filters];
    arrF[index].compare = !arrF[index].compare;
    setFilters([...arrF]);
  };

  const getOption = (options, val) => {
    return options.find((opt) => opt.name === val);
  };

  const handleFilterNamesExcluded = () => {
    const _filterNames = filterNames.filter(
      (item) => exclude.indexOf(item.name) === -1
    );
    setFilterNamesExcleded([..._filterNames]);
  };

  useEffect(() => {
    handleFilterNamesExcluded();
  }, [exclude]);

  const constructRanges = () => {
    const arr = predefinedRanges.map((item) => ({
      name: item.label,
      title: item.label,
    }));
    arr.unshift({
      title: "Без времени",
      name: null,
    });
    return arr;
  };

  return (
    <Modal open={open} close={close}>
      <Box className={classes.head}>
        <CustomSelect
          options={constructOptionsFilters()}
          value={currentFilter}
          callback={handleCurrentFilter}
          sx={{ width: "200px" }}
          placeholder={"Выбрать шаблон"}
        />

        <CustomInput
          sx={{
            marginLeft: "15px",
            width: "150px",
          }}
          value={filtersName}
          callback={handleFiltersName}
          placeholder={"Имя шаблона"}
        />
        {filtersName &&
        filtersSaved.find((item) => item.name === filtersName) ? (
          <IconButton sx={{ marginLeft: "7px" }} onClick={deleteFilter}>
            <DeleteIcon />
          </IconButton>
        ) : null}
        <CustomSelect
          options={constructRanges()}
          value={dateRange}
          callback={handleDateRangeFilt}
          sx={{ marginLeft: "10px" }}
        />
        <Button
          variant="outlined"
          onClick={saveNewFilter}
          sx={{ marginLeft: "15px" }}
        >
          Сохранить
        </Button>
        <Button
          variant="outlined"
          color="error"
          sx={{
            margin: "0px 15px",
            color: "rgb(241, 89, 92)",
            borderColor: "rgb(241, 89, 92)",
          }}
          onClick={clearAll}
        >
          Очистить все
        </Button>
      </Box>

      {filters.map((filt, i) => (
        <Box className={classes.box} key={`${i}${filt.name}`}>
          <IconButton onClick={() => deleteF(i)}>
            <CloseIcon
              sx={{
                width: "0.6em",
                height: "0.6em",
              }}
            />
          </IconButton>
          <CustomSelect
            options={filterNamesExcluded}
            value={filt.name}
            callback={(val) => handleFilters("name", i, val)}
            sx={{
              minWidth: "250px",
            }}
            placeholder={"Выбрать фильтр"}
          />
          <CustomSelect
            options={filterSettings[filt.name]?.types}
            value={filt.type}
            callback={(val) => handleFilters("type", i, val)}
            sx={{ minWidth: "200px", margin: "0px 15px" }}
            placeholder={"Выбрать действие"}
            disabled={!filt.name}
          />
          {filt.name === "created_at" || filt.name === "send_date" ? (
            <CustomDatePicker
              value={filt.value}
              callback={(val) => handleFilters("value", i, val["$d"])}
              placeholder={"Ввести значение"}
              disabled={!filt.type}
            />
          ) : fetchedFiltersRe[filt.name]?.length ? (
            <CustomSeachSelect
              options={fetchedFiltersRe[filt.name]}
              value={getOption(fetchedFiltersRe[filt.name], filt.value)}
              callback={(val) => handleFilters("value", i, val)}
              placeholder={"Ввести значение"}
              sx={{ minWidth: "260px" }}
              disabled={!filt.type}
            />
          ) : (
            <CustomInput
              value={filt.value}
              callback={(val) => handleFilters("value", i, val)}
              sx={{
                minWidth: "260px",
              }}
              placeholder={"Ввести значение"}
              disabled={!filt.type}
            />
          )}
          {i > 0 ? (
            <FormControlLabel
              control={
                <Switch
                  checked={filters[i - 1].compare}
                  onChange={() => handleCompare(i - 1)}
                />
              }
              labelPlacement="start"
              label="И / Или"
              sx={{ position: "absolute", top: "-27.5px", left: "770px" }}
            />
          ) : null}
        </Box>
      ))}

      <Box className={classes.bottom}>
        <Box>
          <Button variant="contained" onClick={addFilter}>
            Добавить фильтр
            <AddIcon />
          </Button>
          <FormControlLabel
            control={<Switch checked={enabled} onChange={handleEnabled} />}
            labelPlacement="start"
            label="Включить фильтры"
          />
        </Box>
        <Button variant="contained" onClick={find}>
          Поиск
          <SearchIcon />
        </Button>
      </Box>
    </Modal>
  );
}
