import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Box, Typography } from "@mui/material";

const PieChart = ({ data, caption }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data && chartRef.current) {
      const myChart = new Chart(chartRef.current, {
        type: "pie",
        data: {
          labels: data.labels,
          datasets: [
            {
              data: data.values,
              backgroundColor: data.colors,
              borderWidth: data.values.length === 1 ? 0 : 2,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              position: "bottom",
            },
          },
        },
      });

      return () => {
        myChart.destroy();
      };
    }
  }, [data]);

  return (
    <Box>
      {caption && (
        <Typography
          sx={{
            fontSize: "15px",
            marginBottom: "12px",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          {caption}
        </Typography>
      )}
      <canvas ref={chartRef} style={{ maxWidth: "200px" }} />
    </Box>
  );
};

export default PieChart;
