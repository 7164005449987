export const profileParams = {
  Account: [
    { id: 'id', label: 'ID', },
    { id: 'created_at', label: 'Дата регистрации', },
    { id: 'updated_at', label: 'Дата обновления', },
    { id: 'name', label: 'Имя', },
    { id: 'status', label: 'Статус', },
    { id: 'currency', label: 'Валюта', },
    { id: 'language', label: 'Язык', },
    { id: 'ip', label: 'IP', }
  ],
  Contacts: [
    { id: 'send_at', label: 'Дата последнего звонка', },
    { id: 'email', label: 'Email', },
    { id: 'phone', label: 'Телефон', },
  ],
  Marketing: [
    { id: 'source', label: 'Источник', },
    { id: 'desc', label: 'Описание', },
  ],
  Assigments: [
    { id: 'crm_id', label: 'CRM ID', },
    { id: 'affiliate', label: 'Назначенный Аффилиат', },
    { id: 'campaign', label: 'Кампейн', },
  ],
  Extra: [
    { id: 'country', label: 'Страна', },
    { id: 'custom_param1', label: 'Сustom 1', },
    { id: 'custom_param2', label: 'Сustom 2', },
    { id: 'custom_param3', label: 'Сustom 3', },
    { id: 'custom_param4', label: 'Сustom 4', },
    { id: 'custom_param5', label: 'Сustom 5', },
    { id: 'custom_param6', label: 'Сustom 6', },
  ],
  Finances: [
    { id: 'ftd_amount', label: 'Сумма FTD', },
    { id: 'ftd_status', label: 'Статус FTD', },
  ]
}