import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader({sx}) {
  const useStyles = makeStyles((theme) => ({
    cont: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "calc(100vh - 160px)",
      ...sx
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.cont}>
      <CircularProgress />
    </Box>
  );
}
