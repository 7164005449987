import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { IconButton, Box, Button, Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import CustomInput from "../../Components/CustomInput";
import GlobalLoader from "../../Components/GlobalLoader";
import ModalUser from "../../Components/ModalEditUser";
import ModalCreateUser from "../../Components/ModalCreateUser";
import { userListHeadCells } from "../../config/heads";
import { fetchAdminUsers, fetchAdminUserCreate, fetchAdminUserUpdate, fetchCountries, fetchNewCountry } from "../../api/api";
import { getTokenValue } from "../../config/cookie";

const getCountries = (country, countryItem) => {
  if (!country.length) return;
  const _countries = country.find((item) => item.id === countryItem);
  return _countries?.name;
};

const useStyles = makeStyles((theme) => ({
  cont: {
    width: "100%",
    marginTop: "20px",
  },
  head: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
  },
  title: {
    fontSize: "30px",
  },
  row: {
    "&:last-child td, &:last-child th": { border: 0 },
    "& .MuiTableCell-root": {
      padding: "10px",
      border: "none",
    },
  },
}));

const Row = ({ row, i, handleModal, classes, country }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        key={row.id}
        className={classes.row}
      >
        <TableCell>
          {row.country ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            <DoNotDisturbIcon />
          )}
        </TableCell>
        <TableCell component="th" scope="row" align="right">
          {row.id}
        </TableCell>
        <TableCell align="right">{row.name}</TableCell>
        <TableCell align="right">{row.email}</TableCell>
        <TableCell align="right">{row.created_at}</TableCell>
        <TableCell align="right">
          <IconButton onClick={() => handleModal(row.id)}>
            <SettingsIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow sx={{ backgroundColor: "#e5e7eb" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Countries
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#e5e7eb" }}>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.country?.map((item) => (
                    <TableRow key={`coutry-${item}`}>
                      <TableCell component="th" scope="row">
                        {item}
                      </TableCell>
                      <TableCell>{getCountries(country, item)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default function UserList({ setSnack }) {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [modalData, setModalData] = useState({});
  const [modalCreateData, setModalCreateData] = useState({
    name: '',
    password: '',
    email: '',
    role: 'user',
  });
  const [open, setOpen] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [country, setCountries] = useState([]);
  const [newCountry, setNewCountry] = useState('');

  const handleModal = (id) => {
    const _data = [...users];
    const index = _data.findIndex((item) => item.id === id);
    setModalData({ ..._data[index] });
    setOpen(true);
  };

  const getUsers = async () => {
    try {
      const res = await fetchAdminUsers(getTokenValue());
      if (res.success) {
        setUsers(res.data);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: JSON.stringify(err),
        type: "error",
        open: true,
      });
    }
  };

  const getCountriesData = async () => {
    setLoading(true);
    try {
      const res = await fetchCountries(getTokenValue());
      if (res.success) {
        setCountries([...res.data]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setLoading(false);
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  };

  const handleUserCreate = async () => {
    try {
      const res = await fetchAdminUserCreate(getTokenValue(), { ...modalCreateData });
      if (res.success) {
        const _data = [...users];
        _data.push({ ...modalCreateData });
        setUsers([..._data]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  }

  const handleUserUpdate = async () => {
    try {
      const res = await fetchAdminUserUpdate(getTokenValue(), { ...modalData });
      if (res.success) {
        const _data = [...users];
        const index = _data.findIndex((item) => item.id === modalData.id);
        _data[index] = { ...modalData };
        setData([..._data]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  };

  const addCountry = async () => {
    try {
      const res = await fetchNewCountry(getTokenValue(), { name: newCountry });
      if (res.success) {
        getCountriesData();
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    } finally {
      setNewCountry('');
    }
  };

  const handleNewCountry = (val) => {
    setNewCountry(val);
  }

  useEffect(() => {
    getCountriesData();
    getUsers();
  }, []);

  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <>
          {open ? (
            <ModalUser
              open={open}
              setOpen={setOpen}
              data={modalData}
              setData={setModalData}
              callback={handleUserUpdate}
              setSnack={setSnack}
              country={country}
            />
          ) : null}
          {openCreateModal ? (
            <ModalCreateUser
              open={openCreateModal}
              setOpen={setOpenCreateModal}
              data={modalCreateData}
              setData={setModalCreateData}
              callback={handleUserCreate}
              setSnack={setSnack}
            />
          ) : null}
          <Box className={classes.cont}>
            <Box className={classes.head}>
              <Typography sx={{ fontSize: "20px" }}>User List</Typography>
              <Button
                onClick={() => setOpenCreateModal(true)}
                variant="contained"
                sx={{
                  minWidth: "150px",
                  height: "36.5px",
                  marginLeft: "20px",
                }}
              >
                Create
              </Button>
            </Box>

            <Box 
              className={classes.box}
              sx={{ 
                marginBottom: "15px", 
                padding: "1.25rem", 
                backgroundColor: "#FFF",  
                borderRadius: "0.375rem",
                boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16)", 
              }} 
            >
              <CustomInput
                placeholder={"Country name"}
                callback={handleNewCountry}
                value={newCountry}
              />
              <Button
                variant="contained"
                sx={{ marginLeft: "25px" }}
                onClick={addCountry}
              >
                Add country
              </Button>
            </Box>
  
            <TableContainer 
              sx={{ padding: "1.25rem 1.25rem 2.25rem", boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16)", }} 
              component={Paper}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {userListHeadCells.map((item, i) => (
                      <TableCell align={i === 0 ? "left" : "right"} key={item.id}>
                        {item.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((row, i) => (
                    <Row
                      key={i}
                      row={row}
                      i={i}
                      handleModal={handleModal}
                      classes={classes}
                      country={country}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
}