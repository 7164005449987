import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  fetchStats,
  fetchStatsAffiliates,
  fetchStatsCampaigns,
  fetchStatsMain,
  fetchUsers,
  fetchCountries,
} from "../../api/api";
import { getTokenValue } from "../../config/cookie";
import {
  Box,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { statisticsCardParams } from "../../config/statistics";
import StatCard from "../../Components/StatCard";
import PieChart from "../../Components/PieChart";
import GlobalLoader from "../../Components/GlobalLoader";
import TableStats from "../../Components/TableStats";
import CustomDateRange from "../../Components/CustomDateRange";
import CustomSelect from "../../Components/CustomSelect";
import CustomSeachSelect from "../../Components/CustomSeachSelect";
import CustomInput from "../../Components/CustomInput";

const useStyles = makeStyles((theme) => ({
  cont: {
    width: "100%",
    marginTop: "20px",
    paddingBottom: "40px",
  },
  head: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "25px",
  },
  title: {
    fontSize: "30px",
  },
  list: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "25px 50px",
    minWidth: "50%",
  },
  chartList: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
    marginLeft: "20px",
    marginTop: "28px",
    padding: "30px",
    backgroundColor: "rgba(70, 105, 250, 0.1)",
    borderRadius: "10px",
    height: "286px",
  },
}));

const dayMilliseconds = 24 * 60 * 60 * 1000;

var startDate = new Date();
startDate = startDate.setTime(startDate.getTime() - dayMilliseconds);
var endDate = new Date();

export default function Stats({ setSnack }) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [dataMain, setDataMain] = useState({});
  const [dataCampaigns, setDataCampaigns] = useState({});
  const [dataAffiliates, setDataAffiliates] = useState({});
  const [search, setSearch] = useState("");
  const [campaignsSearch, setCampaignsSearch] = useState([]);
  const [dataTable, setDataTable] = useState({
    cr: [],
    leads: [],
  });
  const [dataTableLength, setDataTableLength] = useState({
    cr: 5,
    leads: 5,
    campaigns: 5,
  });
  const [chartData, setСhartData] = useState({
    all_lead: {
      labels: [],
      values: [],
    },
    clear_lead: {
      labels: [],
      values: [],
    },
    current_ftd: {
      labels: [],
      values: [],
    },
  });
  const [date, setDate] = useState([endDate, endDate]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState("");
  const [users, setUsers] = useState([]);
  const [regions, setRegions] = useState([]);
  const [currentRegion, setCurrentRegion] = useState('');

  const handleRegion = (val) => {
    setCurrentRegion(val);
    localStorage.setItem("currentRegion", val);
  };

  const handleUser = (val) => {
    setUser(val);
  };

  const getCountries = async () => {
    try {
      const res = await fetchCountries(getTokenValue());
      if (res.success) {
        const transformedData = res.data.map(item => ({
          id: item.id,
          name: item.name,
          title: item.name,
        }));
        setRegions([...transformedData]);
        setCurrentRegion(localStorage.getItem("currentRegion")
          ? localStorage.getItem("currentRegion")
          : transformedData[0].name);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  }

  const getChartsData = (data) => {
    const _data = {
      all_lead: {
        labels: [],
        values: [],
      },
      clear_lead: {
        labels: [],
        values: [],
      },
      current_ftd: {
        labels: [],
        values: [],
      },
    };

    for (let item of Object.keys(data)) {
      if (data[item].all_lead > 0) {
        _data.all_lead.labels.push(item);
        _data.all_lead.values.push(data[item].all_lead);
      }

      if (data[item].clear_lead > 0) {
        _data.clear_lead.labels.push(item);
        _data.clear_lead.values.push(data[item].clear_lead);
      }

      if (data[item].current_ftd > 0) {
        _data.current_ftd.labels.push(item);
        _data.current_ftd.values.push(data[item].current_ftd);
      }
    }

    for (let item of Object.keys(_data)) {
      if (_data[item].values.length === 0 && _data[item].labels.length === 0) {
        _data[item].labels.push("No data");
        _data[item].values.push(1);
      }
    }

    return _data;
  };

  const getTableData = (dataLeads) => {
    const _data = {
      cr: [],
      leads: [],
    };

    // const dataLeads = { ...data.sent_leads };

    const keysLeads = Object.keys(dataLeads);
    const lengthCr =
      keysLeads.length < dataTableLength.cr
        ? keysLeads.length
        : dataTableLength.cr;
    const lengthLeads =
      keysLeads.length < dataTableLength.leads
        ? keysLeads.length
        : dataTableLength.leads;

    for (let i = 0; i < keysLeads.length; i++) {
      const obj = {
        name: keysLeads[i],
        ...dataLeads[keysLeads[i]],
      };
      _data.cr.push(obj);
    }
    _data.cr.sort((a, b) => b.cr - a.cr);
    _data.cr.length = lengthCr;

    for (let i = 0; i < keysLeads.length; i++) {
      const obj = {
        name: keysLeads[i],
        ...dataLeads[keysLeads[i]],
      };
      _data.leads.push(obj);
    }
    _data.leads.sort((a, b) => b.all_lead - a.all_lead);
    _data.leads.length = lengthLeads;

    return _data;
  };

  const getTableDataCampaigns = (data) => {
    let _data = [];
    const keysCampaigns = Object.keys(data);

    let lengthCampaigns =
      keysCampaigns.length < dataTableLength.campaigns
        ? keysCampaigns.length
        : dataTableLength.campaigns;

    for (let i = 0; i < keysCampaigns.length; i++) {
      const obj = {
        name: keysCampaigns[i],
        ...data[keysCampaigns[i]],
      };
      _data.push(obj);
    }

    if (search) {
      _data = _data.filter((item) => {
        let name = item.name;
        name = name.split("");
        name.length = search.length;
        name = name.join("");
        return name.toLowerCase() === search.toLowerCase();
      });

      lengthCampaigns = _data.length;
    }

    _data.sort((a, b) => b.all_lead - a.all_lead);
    _data.length = lengthCampaigns;

    return _data;
  };

  const getStatsMain = async () => {
    try {
      const regionId = regions?.find(item => item.name === currentRegion)?.id;
      const _data = {
        start: dayjs(date[0]).format("YYYY-MM-DD"),
        end: dayjs(date[1]).format("YYYY-MM-DD"),
        user: user,
        country_id: regionId,
      };
      const res = await fetchStatsMain(getTokenValue(), _data);
      if (res.success) {
        setDataMain({ ...res.data });
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: JSON.stringify(err),
        type: "error",
        open: true,
      });
    }
  };

  const getStatsAffiliates = async () => {
    try {
      const regionId = regions?.find(item => item.name === currentRegion)?.id;
      const _data = {
        start: dayjs(date[0]).format("YYYY-MM-DD"),
        end: dayjs(date[1]).format("YYYY-MM-DD"),
        user: user,
        country_id: regionId,
      };
      const res = await fetchStatsAffiliates(getTokenValue(), _data);
      if (res.success) {
        setСhartData({ ...getChartsData({ ...res.data }) });
        setDataTable({ ...getTableData({ ...res.data }) });
        setDataAffiliates({ ...res.data });
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: JSON.stringify(err),
        type: "error",
        open: true,
      });
    }
  };

  const getStatsCampaigns = async () => {
    try {
      const regionId = regions?.find(item => item.name === currentRegion)?.id;
      const _data = {
        start: dayjs(date[0]).format("YYYY-MM-DD"),
        end: dayjs(date[1]).format("YYYY-MM-DD"),
        user: user,
        country_id: regionId,
      };
      const res = await fetchStatsCampaigns(getTokenValue(), _data);
      if (res.success) {
        setCampaignsSearch([...getTableDataCampaigns({ ...res.data })]);
        setDataCampaigns({ ...res.data });
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: JSON.stringify(err),
        type: "error",
        open: true,
      });
    }
  };

  const getStats = async () => {
    setLoading(true);
    try {
      const regionId = regions?.find(item => item.name === currentRegion)?.id;
      const _data = {
        start: dayjs(date[0]).format("YYYY-MM-DD"),
        end: dayjs(date[1]).format("YYYY-MM-DD"),
        user: user,
        country_id: regionId,
      };
      const res = await fetchStats(getTokenValue(), _data);
      if (res.success) {
        setData({ ...res.data });
        setСhartData({ ...getChartsData({ ...res.data.sent_leads }) });
        setDataTable({ ...getTableData({ ...res.data }) });
        setCampaignsSearch([
          ...getTableDataCampaigns({ ...res.data.campaigns }),
        ]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setLoading(false);
    } catch (err) {
      setSnack({
        text: JSON.stringify(err),
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  };

  const getUsers = async () => {
    try {
      const res = await fetchUsers(getTokenValue());
      if (res.success) {
        constructUsers(res.data.users);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: JSON.stringify(err),
        type: "error",
        open: true,
      });
    }
  };

  const handleDate = (val) => {
    setDate([...val]);
  };

  const constructUsers = (arr) => {
    if (!arr) return;

    const _users = arr.map((item) => ({
      name: item.id,
      title: item.name,
    }));
    setUsers([..._users]);
  };

  const getOption = (options, val) => {
    return options?.find((opt) => opt.name === val);
  };

  useEffect(() => {
    getCountries();
    getUsers();
  }, []);

  const getAllData = async () => {
    if (!date[0] || !date[1]) return;
    setLoading(true);
    // getStats();
    getStatsMain();
    getStatsAffiliates();
    getStatsCampaigns();
    setLoading(false);
  };

  useEffect(() => {
    if (currentRegion) {
      getAllData();
    }
  }, [date, currentRegion, user]);

  useEffect(() => {
    setDataTable({ ...getTableData({ ...dataAffiliates }) });
  }, [dataTableLength.cr, dataTableLength.leads]);

  useEffect(() => {
    setCampaignsSearch([...getTableDataCampaigns({ ...dataCampaigns })]);
  }, [dataTableLength.campaigns, search]);

  const handleLength = (target, side) => {
    const _data = { ...dataTableLength };
    if (!side && _data[target] - 5 <= 0) return;
    _data[target] = side ? _data[target] + 5 : _data[target] - 5;
    setDataTableLength({ ..._data });
  };

  const handleSearch = (val) => {
    setSearch(val);
  };

  return (
    <>
      {loading ? (
        <GlobalLoader />
      ) : (
        <Box className={classes.cont}>
          <Box className={classes.head}>
            <Typography sx={{ fontSize: "20px" }}>Statistics</Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <CustomDateRange callback={handleDate} value={date} />
              <CustomSeachSelect
                value={getOption(users, user)}
                options={users}
                placeholder={"Select User"}
                callback={handleUser}
                sx={{ marginLeft: "15px" }}
                sxInput={{ backgroundColor: "#fff" }}
              />
              <CustomSelect
                value={currentRegion}
                options={regions}
                placeholder={"Select region"}
                callback={handleRegion}
                sx={{ marginLeft: "15px", backgroundColor: "#fff" }}
              />
            </Box>
          </Box>

          <Box
            sx={{ 
              display: "flex",
              marginBottom: "48px",
              padding: "1.25rem", 
              backgroundColor: "#FFF",
              borderRadius: "0.375rem",
              boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16)", 
            }}
          >
            <Box className={classes.list}>
              {statisticsCardParams.map((item) => (
                <StatCard
                  key={item.id}
                  param={item.title}
                  value={dataMain[item.id] ? dataMain[item.id] : 0}
                />
              ))}
            </Box>

            <Box className={classes.chartList}>
              <PieChart data={chartData.all_lead} caption="all_lead" />
              <PieChart data={chartData.clear_lead} caption="clear_lead" />
              {/* <PieChart data={chartData.current_ftd} caption="current_ftd" /> */}
            </Box>
          </Box>

          <div
            style={{
              marginBottom: "48px",
              padding: "1.25rem", 
              backgroundColor: "#FFF",
              borderRadius: "0.375rem",
              boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16)", 
            }}
          >
            <Typography gutterBottom variant="h5">
              {`Top ${dataTableLength.cr} affiliates by conversion`}
            </Typography>
            <TableStats
              data={dataTable.cr}
              handleLength={handleLength}
              target={"cr"}
              length={dataTableLength.cr}
            />
          </div>

          <div
            style={{
              marginBottom: "48px",
              padding: "1.25rem", 
              backgroundColor: "#FFF",
              borderRadius: "0.375rem",
              boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16)", 
            }}
          >
            <Typography gutterBottom variant="h5">
              {`Top ${dataTableLength.leads} affiliates by leads`}
            </Typography>
            <TableStats
              data={dataTable.leads}
              handleLength={handleLength}
              target={"leads"}
              length={dataTableLength.leads}
            />
          </div>

          <div
            style={{
              padding: "1.25rem", 
              backgroundColor: "#FFF",
              borderRadius: "0.375rem",
              boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16)", 
            }}
          >
            <Typography gutterBottom variant="h5">
              {`Top ${dataTableLength.campaigns} affiliates by campaigns`}
            </Typography>
            <CustomInput
              placeholder={"Search"}
              callback={handleSearch}
              value={search}
              sx={{ width: "300px", marginBottom: "10px" }}
            />
            <TableStats
              data={campaignsSearch} //  dataTable.campaigns
              handleLength={handleLength}
              target={"campaigns"}
              length={dataTableLength.campaigns}
            />
          </div>
        </Box>
      )}
    </>
  );
}
