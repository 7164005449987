import { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import CustomTabPanel from "./CustomTabPanel";
import LogsInfo from "./LogsInfo";
import FraudInfo from "./FraudInfo";

function CustomTabs({ tabsParams, id }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs className="tabsss" value={value} onChange={handleChange} aria-label="basic tabs" centered>
          {tabsParams.heads.map(tab => <Tab key={tab.id} label={tab.label} value={tab.id} />)}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div style={{ width: "100%", overflow: "auto" }}>
          <LogsInfo id={id} />
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div style={{ width: "100%", overflow: "auto" }}>
          <FraudInfo id={id} />
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel>
    </div>
  );
}

export default CustomTabs;