import { makeStyles } from "@mui/styles";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const useStyles = makeStyles((theme) => ({
  cont: {
    "& .profile-param__item": {
      marginBottom: "12px",
      fontSize: "0.75rem",
    },
    "& dt": { 
      marginBottom: "6px",
      fontWeight: 500, 
      color: "hsl(232 14% 68%)",
    },
    "& dd": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "6px 10px",
      color: "#363636",
      marginBottom: "0px",
      borderBottom: "1px solid #b5b5b6",
      cursor: "pointer",
      "& .copy-icon": {
        width: "14px",
        height: "14px",
      }
    },
  },
}));

function LeadCard({ data, params, copyText }) {
  const classes = useStyles();

  return (
    <dl className={classes.cont}>
      {params.map((param) => (
        <div key={param.id} className="profile-param__item">
          <dt>{param.label}</dt>
          <dd onClick={() => copyText(data[param.id] ? data[param.id] : "None")}>
            <span>{data[param.id] ? data[param.id] : "None"}</span>
            <ContentCopyIcon className="copy-icon" />
          </dd>
        </div>
      ))}
    </dl>
  );
}
export default LeadCard;
