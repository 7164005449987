export const headCells = [
    {
        id: "id",
        numeric: true,
        disablePadding: false,
        label: "ID",
    },
    {
        id: "country",
        numeric: false,
        disablePadding: true,
        label: "Страна",
    },
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Имя",
    },
    {
        id: "email",
        numeric: true,
        disablePadding: false,
        label: "Email",
    },
    {
        id: "phone",
        numeric: true,
        disablePadding: false,
        label: "Телефон",
    },
    {
        id: "status",
        numeric: true,
        disablePadding: false,
        label: "Статус",
    },
    {
        id: "campaign",
        numeric: true,
        disablePadding: false,
        label: "Кампейн",
    },
    {
        id: "affiliate",
        numeric: true,
        disablePadding: false,
        label: "Покупатель",
    },
    {
        id: "landing",
        numeric: true,
        disablePadding: false,
        label: "Лендинг",
    },
    {
        id: "timezone",
        numeric: true,
        disablePadding: false,
        label: "Время",
    },
    {
        id: "desc",
        numeric: true,
        disablePadding: false,
        label: "Описание",
    },
    {
        id: "comment",
        numeric: true,
        disablePadding: false,
        label: "Комментарий",
    },
    {
        id: "source",
        numeric: true,
        disablePadding: false,
        label: "Источник",
    },
    {
        id: "user",
        numeric: false,
        disablePadding: true,
        label: "Пользователь",
    },
    {
        id: "created_at",
        numeric: true,
        disablePadding: false,
        label: "Дата",
    },
    {
        id: "send_date",
        numeric: true,
        disablePadding: false,
        label: "Дата отправки",
    },
    {
        id: "custom_param1",
        numeric: true,
        disablePadding: false,
        label: "Custom 1",
    },
    {
        id: "custom_param2",
        numeric: true,
        disablePadding: false,
        label: "Custom 2",
    },
    {
        id: "custom_param3",
        numeric: true,
        disablePadding: false,
        label: "Custom 3",
    },
    {
        id: "custom_param4",
        numeric: true,
        disablePadding: false,
        label: "Custom 4",
    },
    {
        id: "custom_param5",
        numeric: true,
        disablePadding: false,
        label: "Custom 5",
    },
    {
        id: "custom_param6",
        numeric: true,
        disablePadding: false,
        label: "Custom 6",
    },
];



export const affiliateHeadCells = [
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Name' },
    { id: 'status', label: 'Active' },
    { id: 'current_daily', label: 'Current Quota' },
    { id: 'max_daily', label: 'Daily Quota' },
    { id: 'start_traffic', label: 'Start Traffic' },
    { id: 'stop_traffic', label: 'Stop Traffic' },
    { id: 'action', label: '...' },
];

export const userListHeadCells = [
    { id: 'countries', label: '' },
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'created_at', label: 'Created_at' },
    { id: 'action', label: '...' },
];

export const logsHeadCells = [
    { id: 'affiliate', label: 'Affiliate' },
    { id: 'created_at', label: 'Created_at' },
    { id: 'status', label: 'Status' },
    { id: 'response', label: 'Response' },
];

export const fraudHeadCells = [
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Name' },
    { id: 'source', label: 'Source' },
    { id: 'created_at', label: 'Created_at' },
    { id: 'landing', label: 'Landing' },
];

export const statsHeadCells = [
    { id: 'name', label: 'Name' },
    { id: 'all_lead', label: 'Leads' },
    { id: 'clear_lead', label: 'Clear Leads' },
    { id: 'total_ftd', label: 'Total FTD' },
    { id: 'current_ftd', label: 'Current FTD' },
    { id: 'ccr', label: 'CCR' },
    { id: 'new_ftd', label: 'New FTD' },
    { id: 'fcr', label: 'FCR' },
    { id: 'cr', label: 'CR' },
];

export const domainsHeadCells = [
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Name' },
    { id: 'user', label: 'User' },
    { id: 'created_at', label: 'Created_at' },
];