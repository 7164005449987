export const colors = {
    "New Lead": "rgb(210 130 58 / 24%)",
    "Depozit": "#FB79BF",
    "Platezhka": "#7495EB",
    "Dozvonitsya": "#75E87A",
    "Perezvon": "#75E87A",
    "Dubl": "#000",
    "Nbt": "#E0FE2C",
    "Otkaz": "#F93030",
    "Reserv": "#0372F3",
    "Nerezident": "#000",
    "Net 18": "#000",
    "Nevernyy nomer": "#000",
    "Ne govorit po-russki": "#000",
    "Old lead": "#ABB6B1",
    "Broke": "#ABB6B1",
    "Allo sbros": "#ABB6B1",
    "MFO": "#ABB6B1",
    "Wrong Info": "#000",
    "Hold": "rgb(255 248 0 / 37%)"
}