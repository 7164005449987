import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import CustomInputDate from "./CustomInputDate";
import CustomDatePicker from "./CustomDatePicker";
import SearchIcon from "@mui/icons-material/Search";
import CustomDateRange from "./CustomDateRange";

export default function ({ callback, filters, reset, close, target }) {
  const [values, setValues] = useState([]);

  const handleDate = (val) => {
    callback([val[0], val[1]]);
  };

  useEffect(() => {
    const _values = [];
    const indexStart = filters.findIndex(
      (f) => f.name === target && f.type === "greater"
    );
    const indexEnd = filters.findIndex(
      (f) => f.name === target && f.type === "less"
    );

    if (indexStart !== -1) {
      _values[0] = filters[indexStart].value;
    }
    if (indexEnd !== -1) {
      _values[1] = filters[indexEnd].value;
    }

    const transformedDate = _values.map(date => new Date(date));

    setValues([...transformedDate]);
  }, [filters]);

  return (
    <>
      <FormGroup
        sx={{
          height: "110px",
          width: "300px",
          flexDirection: "column",
          flexWrap: "nowrap",
          overflowY: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
          }}
        >
          <CustomDateRange callback={handleDate} value={values} />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button variant="reset" sx={{ marginRight: "10px" }} onClick={reset}>
            Reset
          </Button>
          <Button variant="contained" onClick={close}>
            Search <SearchIcon />
          </Button>
        </Box>
      </FormGroup>
    </>
  );
}
