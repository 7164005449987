import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import KeyIcon from "@mui/icons-material/Key";
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import DnsIcon from '@mui/icons-material/Dns';
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { Link, useLocation } from "react-router-dom";
import { unSetToken } from "../config/cookie";

const siteMenuItems = [
  { name: 'Leads', pathname: '/', icon: PeopleAltIcon, role: 'bayer' },
  { name: 'Affiliates', pathname: '/affiliates', icon: ApartmentIcon, role: 'admin' },
  { name: 'Statistics', pathname: '/statistics', icon: AssessmentIcon, role: 'bayer' },
  { name: 'Api keys', pathname: '/user-keys', icon: KeyIcon, role: 'bayer' },
  { name: 'User List', pathname: '/user-list', icon: RecentActorsIcon, role: 'admin' },
  { name: 'Domains', pathname: '/domains', icon: DnsIcon, role: 'bayer' },
];

const userMenuItems = [
  { name: 'User', pathname: '/', icon: PersonIcon },
  { name: 'Logout', pathname: '/affiliates', icon: ExitToAppIcon },
];

const useStyles = makeStyles((theme) => ({
  sidebarCont: {
    width: "100%",
    padding: "0 12px",
    transition: theme.transitions.create(["width", "transform"], {
      duration: theme.transitions.duration.standard,
    }),
    overflow: "hidden",
    backgroundColor: theme.palette.menu.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16)",
  },
  list: {
    display: "flex",
    padding: "0 !important",
  },
  listItem: {
    height: "40px",
    margin: "0",
    color: theme.palette.secondary.main,
    cursor: "pointer",
    borderRadius: "0.375rem",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  listText: {
    fontSize: "0.875rem",
    fontWeight: "500",
  },
  listItemActive: {
    color: theme.palette.primary.main,
    backgroundColor: "rgba(226, 232, 240, 0.5)",
  },
  listIcon: {
    color: theme.palette.secondary.main,
  },
  listIconActive: {
    color: theme.palette.primary.main,
  }
}));

function Menu({ setIsLogin, role }) {
  const location = useLocation();

  const logout = () => {
    unSetToken();
    setIsLogin(false);
  };

  const classes = useStyles();

  return (
    <Box className={classes.sidebarCont}>
      <List className={classes.list}>
        {siteMenuItems.map(item => {
          const Icon = item.icon;

          return (role === 'admin' || role === item.role) &&
          <Link to={item.pathname} key={item.name}>
            <ListItem
              className={
                `${classes.listItem} ${location.pathname === item.pathname && 
                  classes.listItemActive}`
              }
            >
              <ListItemIcon sx={{ minWidth: "0", marginRight: "8px" }}>
                <Icon 
                  className={
                    `${classes.listIcon} ${location.pathname === item.pathname && 
                      classes.listIconActive}`
                  }
                />
              </ListItemIcon>
              <p className={classes.listText}>{item.name}</p>
            </ListItem>
          </Link>
        })}
      </List>

      <List className={classes.list}>
        {userMenuItems.map(item => {
          const Icon = item.icon;

          return (
            <ListItem 
              className={classes.listItem} 
              onClick={item.name === 'Logout' ? logout : () => {}} 
              key={item.name}
            >
              <ListItemIcon sx={{ minWidth: "0", marginRight: "8px" }}>
                <Icon className={classes.listIcon} />
              </ListItemIcon>
              <p className={classes.listText}>{item.name}</p>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

export default Menu;