import { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  ClickAwayListener,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "./CustomInput";
import { roles } from "../config/roles";

const useStyles = makeStyles((theme) => ({
  cont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "20px",
    width: "600px",
    position: "relative",
    backgroundColor: "#e5e7eb!important",
  },
  box: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    margin: "20px 0px",
    padding: "0px 10px",
  },
  boxItem: {
    width: "45%",
    margin: "7px 0px",
  },
  close: {
    position: "absolute!important",
    top: "10px",
    right: "10px",
  },
  listItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function ModalUser({
  open,
  setOpen,
  data,
  setData,
  callback,
  setSnack,
  country,
}) {
  const classes = useStyles();
  const [searchCountries, setSearchCountries] = useState("");
  const [countriesSearch, setCountriesSearch] = useState([]);
  const [selectAllCountries, setSelectAllCountries] = useState(false);

  useEffect(() => {
    if (!country?.length) return;
    setCountriesSearch([...country]);
  }, [country]);

  const handleSelectAllCountries = () => {
    const old = selectAllCountries;
    setSelectAllCountries(!old);
    const _data = { ...data };

    if (old) {
      _data.country = [];
      setData({ ..._data });
      return;
    } else {
      _data.country = countriesSearch.map((item) => item.id);
      setData({ ..._data });
      return;
    }
  };

  const handleSearchCountries = (val) => {
    setSearchCountries(val);
    if (!val) {
      setCountriesSearch([...country]);
      return;
    }

    const arr = country.filter((item) => {
      let name = item.name;
      name = name.split("");
      name.length = val.length;
      name = name.join("");
      return name.toLowerCase() === val.toLowerCase();
    });

    setCountriesSearch([...arr]);
  };

  const isCountryChecked = (id) => {
    if (!data.country?.length) return false;
    const index = data.country?.findIndex((item) => item === id);
    return index !== -1;
  };

  const handleData = (value, target) => {
    const _data = { ...data };
    if (target === "country") {
      const index = _data.country?.length
        ? _data.country.findIndex((item) => item === value)
        : -1;
      if (_data.country === null) _data.country = [value];
      else if (index === -1) _data.country.push(value);
      else _data.country.splice(index, 1);
      _data.country.sort((a, b) => a - b);
    } else {
      _data[target] = value;
    }
    
    setData({ ..._data });
  }

  const close = () => {
    setOpen(false);
    setData({});
  };

  const save = () => {
    callback();
    close();
  };

  return (
    <Backdrop open={open} sx={{ zIndex: "3" }}>
      <ClickAwayListener onClickAway={close}>
        <Paper className={classes.cont}>
          <IconButton className={classes.close} onClick={close}>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>
            Edit User
          </Typography>

          <Box className={classes.box}>
            <Box className={classes.boxItem}>
              <Typography gutterBottom>ID</Typography>
              <CustomInput 
                value={data.id} 
                sx={{ width: "100%" }}
                disabled
              />
            </Box>

            <Box className={classes.boxItem}>
              <Typography gutterBottom>Role</Typography>
              <select
                style={{width:"100%", minHeight: "1.4375em", padding: "7.5px 14px", backgroundColor: "#F0F0F0", border: "none", borderRadius: "8px", outlineColor: "#4669FA"}}
                value={data.role}
                onChange={(evt) => handleData(evt.target.value, "role")}
              >
                {roles.map(item => (
                  <option value={item.name} key={item.name}>{item.title}</option>
                ))}
              </select>
            </Box>
          </Box>

          <CustomInput
            placeholder={"Search"}
            callback={handleSearchCountries}
            value={searchCountries}
            sx={{ width: "100%" }}
          />
          <Box
            className={classes.box}
            sx={{ height: "300px", overflowY: "auto", marginTop: "5px" }}
          >
            <FormGroup sx={{ width: "100%", paddingRight: "20px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAllCountries}
                    onChange={handleSelectAllCountries}
                  />
                }
                label={"Select All"}
              />
              {countriesSearch?.map((item, i) => (
                <Box className={classes.listItem} key={item.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCountryChecked(item.id)}
                        onChange={() => handleData(item.id, "country")}
                      />
                    }
                    label={item.name}
                  />
                  <Typography>ID: {item.id}</Typography>
                </Box>
              ))}
            </FormGroup>
          </Box>

          <Button variant="contained" sx={{ width: "100%" }} onClick={save}>
            Save
          </Button>
        </Paper>
      </ClickAwayListener>
    </Backdrop>
  );
}
