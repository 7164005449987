import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import CustomInput from "./CustomInput";

export default function ({
  filters,
  callback,
  close,
  reset,
  filtersVariants,
  target,
}) {
  const [search, setSearch] = useState("");
  const [filtersSearch, setFiltersSearch] = useState([...filtersVariants]);

  const isFiltred = (id) =>
    filters.findIndex((item) => item.value === id && item.name === target) !==
    -1;

  const handleSearch = (val) => {
    setSearch(val);
    if (!val) {
      setFiltersSearch([...filtersVariants]);
      return;
    }

    const arr = filtersVariants.filter((item) => {
      let name = item.name;
      name = name.split("");
      name.length = val.length;
      name = name.join("");
      return name.toLowerCase() === val.toLowerCase();
    });

    setFiltersSearch([...arr]);
  };

  const content = filtersSearch?.map((item, i) => (
    <FormControlLabel
      key={`${item.id}${i}`}
      control={
        <Checkbox
          checked={isFiltred(item.id)}
          onChange={() => callback(item.id)}
        />
      }
      label={item.name}
    />
  ));

  return (
    <>
      <FormGroup
        sx={{
          height: "350px",
          flexDirection: "column",
          flexWrap: "nowrap",
          overflowY: "hidden",
        }}
      >
        <CustomInput
          placeholder={"Search"}
          callback={handleSearch}
          value={search}
          sx={{ width: "100%" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "312px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              overflowY: "auto",
              marginTop: "10px",
            }}
          >
            {content}
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "20px",
              marginTop: "10px",
            }}
          >
            <Button variant="reset" onClick={reset}>
              Reset
            </Button>
            <Button variant="contained" onClick={close}>
              Ok
            </Button>
          </Box>
        </Box>
      </FormGroup>
    </>
  );
}
