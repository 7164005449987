import { useState, useEffect } from "react";
import { Backdrop, Box, ClickAwayListener, IconButton, Paper, Typography, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { fetchLead } from "../api/api";
import { getTokenValue } from "../config/cookie";
import { profileParams } from "../config/profileParams";
import LeadCard from "./LeadCard";
import GlobalLoader from "./GlobalLoader";
import CustomTabs from "./CustomTabs";
import { leadTabsParams } from "../config/leadTabsParams";
import avatar from "../png/avatar-icon.png";

const useStyles = makeStyles((theme) => ({
  cont: {
    padding: "20px",
    width: "96%",
    minWidth: "90%",
    maxWidth: "96%",
    height: "90%",
    maxHeight: "max-content",
    position: "relative",
    overflow: "auto",
  },
  contHead: {
    display: "grid",
    gridTemplateColumns: "1fr 40px 40px",
    gap: "8px",
  },
  box: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    margin: "20px 0px",
  },
  boxItem: {
    width: "45%",
    margin: "7px 0px",
  },
  cont2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "12px",
    "& h2": { fontSize: "20px", fontWeight: 400 },
    "& .spinner-container": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "300px",
    },
    "& .card--wide": { gridColumn: "span 2" },
  },
  head: {
    "& .avatar-container": {
      margin: "0 auto 12px",
      textAlign: "center",
    },
    "& .avatar": {
      borderRadius: "50%",
    },
    "& .lead__name": {
      all: "unset",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: "max-content",
      margin: "0 auto",
      padding: "6px 10px",
      color: "#363636",
      marginBottom: "0px",
      borderBottom: "1px solid #b5b5b6",
      cursor: "pointer",
      "& .copy-icon": {
        width: "14px",
        height: "14px",
        marginLeft: "20px",
      }
    },
    "& hr": {
      display: "block",
      height: "2px",
      margin: "1.5rem 0",
      backgroundColor: "#f5f5f5",
      border: "none",
    },
  },
  list: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "12px",
  },
}));

export default function ModalLead({ open, setOpen, id, setSnack }) {
  const classes = useStyles();
  const [dataLead, setDataLead] = useState({});
  const [loading, setLoading] = useState(true);

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    setSnack({
      text: "Copied",
      type: "info",
      open: true,
    });
  };

  const getLead = async () => {
    try {
      const res = await fetchLead(getTokenValue(), id);
      if (res.success) {
        setDataLead({ ...res.data });
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
      setLoading(false);
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getLead();
  }, []);

  const handleClose = () => {
    setOpen(false);
  }

  const handleDelete = () => {

  }

  return (
    <Backdrop open={open} sx={{ zIndex: "3" }}>
      <ClickAwayListener onClickAway={handleClose}>
        <Paper className={classes.cont}>
          <div className={classes.contHead}>
            <Typography variant="h2" sx={{ fontSize: "20px", fontWeight: "500" }}>Lead</Typography>
            <Tooltip title="Удалить лид">
              <IconButton className={classes.close} onClick={handleDelete}>
                <DeleteOutlineIcon sx={{ color: "rgb(241, 89, 92)" }} />
              </IconButton>
            </Tooltip>
            <IconButton className={classes.close} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>

          <>
              {loading ? (
                <GlobalLoader />
              ) : (
                <Box className={classes.cont2}>
                  <Box>
                    <Box className={classes.head}>
                      <div className="avatar-container">
                        <img className="avatart" src={avatar} width={100} height={100} alt="" />
                      </div>
                      <button className="lead__name" onClick={() => copyText(dataLead.name)}>
                        <Typography variant="h2">{dataLead.name}</Typography>
                        <ContentCopyIcon className="copy-icon" />
                      </button>
                      <hr />
                    </Box>
                    <Box className={classes.list}>
                      <LeadCard
                        title="Аккаунт"
                        data={dataLead}
                        params={[...profileParams.Account, ...profileParams.Marketing, ...profileParams.Assigments]}
                        copyText={copyText}
                      />
                      <LeadCard
                        title="Аккаунт"
                        data={dataLead}
                        params={[...profileParams.Contacts, ...profileParams.Finances, ...profileParams.Extra]}
                        copyText={copyText}
                      />
                    </Box>
                  </Box>
                  <CustomTabs tabsParams={leadTabsParams} id={dataLead.id} />
                </Box>
              )}
            </>
        </Paper>
      </ClickAwayListener>
    </Backdrop>
  );
}