import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import useGetDomains from "../hooks/useGetDomains";
import { getDomains } from "../../../api/api";
import { getTokenValue } from "../../../config/cookie";
import PageHead from "../../../Components/PageHead";
import { ModalAddDomains } from "./ModalAddDomains";
import GlobalLoader from "../../../Components/GlobalLoader";
import Row from "./Row";
import { domainsHeadCells } from "../../../config/heads";

function PageContent({ setSnack }) {
  const [modal, setModal] = useState(false);
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchDomains = async () => {
    const token = getTokenValue();
    setLoading(true)
    try {
      const res = await getDomains(token);
      if (res.success) {
        setDomains([...res.data])
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch(error) {
      setSnack({
        text: error.message ? error.message : "Error",
        type: "error",
        open: true,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchDomains();
  }, []);

  const handleCloseModal = () => {
    setModal(false);
    fetchDomains();
  }

  return (
    <>
      {createPortal(
        <ModalAddDomains open={modal} close={handleCloseModal} setSnack={setSnack} />,
        document.body
      )}
      <PageHead title="Domains">
        <Button
          onClick={() => setModal(true)}
          variant="contained"
          sx={{
            minWidth: "150px",
            height: "36.5px",
            marginLeft: "20px",
          }}
        >
          Add
        </Button>
      </PageHead>
      <section>
        {loading ? <GlobalLoader style={{ width: "100%", height: "200px" }} /> :
          <TableContainer 
            sx={{ padding: "1.25rem 1.25rem 2.25rem", boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16)", }} 
            component={Paper}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {domainsHeadCells.map((item, i) => (
                    <TableCell align={i === 0 ? "left" : "right"} key={item.id}>
                      {item.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {domains.map((row) => (
                  <Row
                    key={row.id}
                    row={row}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </section>
    </>
  );
}

export { PageContent };