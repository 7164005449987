export const statisticsCardParams = [
  { id: 'total_leads', title: 'Total Leads' },
  { id: 'clear_leads', title: 'Clear Leads' },
  { id: 'hold_leads', title: 'Hold' },
  { id: 'total_ftd', title: 'Total FTD\'s' },
  { id: 'current_ftd', title: 'Current FTD\'s' },
  { id: 'CCR', title: 'CCR' },
  { id: 'new_ftd', title: 'New FTD\'s' },
  { id: 'FCR', title: 'FCR' },
  { id: 'CR', title: 'CR' },
];