import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import CustomInputDate from "./CustomInputDate";
import CustomDatePicker from "./CustomDatePicker";
import SearchIcon from "@mui/icons-material/Search";
import CustomInput from "./CustomInput";

export default function ({ callback, filters, reset, close }) {
  const [id, setId] = useState("");

  useEffect(() => {
    const index = filters.findIndex((item) => item.name === "id");
    if (index !== -1 && filters[index].value !== "") {
      setId(filters[index].value);
    }
  }, [filters]);

  const search = (val) => {
    if (!val) return;
    callback(val);
  };

  const resetData = ()=>{
    reset();
    setState("");
  }

  return (
    <>
      <FormGroup
        sx={{
          height: "110px",
          width: "260px",
          flexDirection: "column",
          overflowY: "hidden",
        }}
      >
        <CustomInput
          value={id}
          callback={(val) => setId(val)}
          blur={(val) => search(val)}
          placeholder={"ID"}
        />

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button variant="reset" sx={{ marginRight: "10px" }} onClick={resetData}>
            Reset
          </Button>
          <Button variant="contained" onClick={close}>
            Search <SearchIcon />
          </Button>
        </Box>
      </FormGroup>
    </>
  );
}
