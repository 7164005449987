import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BarChartIcon from "@mui/icons-material/BarChart";
const useStyles = makeStyles((theme) => ({
  cont: {
    width: "100%",
    height: "auto",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "32px 20px",
    fontSize: "21px",
    fontWeight: 700,
    color: "#4669FA",
    backgroundColor: "rgba(70, 105, 250, 0.1)",
    border: "none",
    borderRadius: "6px",
  },
  title: {
    fontSize: "15px!important",
    fontWeight: "700!important",
    color: "#0B1921",
  },
  val: {},
}));
function StatCard({ param, value }) {
  const classes = useStyles();

  return (
    <Box className={classes.cont}>
      <Typography className={classes.title} gutterBottom>
        {param}
      </Typography>
      <Box className={classes.box}>
        <BarChartIcon fontSize="30" />
        <span className={classes.val}>
          {param === "CCR" || param === "FCR" || param === "CR"
            ? value + "%"
            : value}
        </span>
      </Box>
    </Box>
  );
}
export default StatCard;
