import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "./CustomInput";
import CustomInputDate from "./CustomInputDate";

const useStyles = makeStyles((theme) => ({
  cont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "20px",
    width: "600px",
    position: "relative",
    backgroundColor: "#e5e7eb!important",
  },
  box: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    margin: "20px 0px",
    padding: "0px 10px",
  },
  boxItem: {
    width: "45%",
    margin: "7px 0px",
  },
  close: {
    position: "absolute!important",
    top: "10px",
    right: "10px",
  },
  listItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function ModalAffiliates({
  open,
  setOpen,
  data,
  setData,
  campaigns,
  users,
  callback,
  setSnack,
}) {
  const classes = useStyles();
  const [searchCampaigns, setSearchCampaigns] = useState("");
  const [campaignsSearch, setCampaignsSearch] = useState([]);
  const [selectAllCampaigns, setSelectAllCampaigns] = useState(false);

  const [searchUsers, setSearchUsers] = useState("");
  const [usersSearch, setUsersSearch] = useState([]);
  const [selectAllUsers, setSelectAllUsers] = useState(false);

  const [tab, setTab] = useState(true);

  useEffect(() => {
    if (!users?.length) return;
    setUsersSearch([...users]);
  }, [users]);

  useEffect(() => {
    if (!campaigns?.length) return;
    setCampaignsSearch([...campaigns]);
  }, [campaigns]);

  const close = () => {
    setOpen(false);
    setData({});
  };

  const handleSelectAllCampaigns = () => {
    const old = selectAllCampaigns;
    setSelectAllCampaigns(!old);
    const _data = { ...data };

    if (old) {
      _data.campaigns = [];
      setData({ ..._data });
      return;
    } else {
      _data.campaigns = campaignsSearch.map((item) => item.id);
      setData({ ..._data });
      return;
    }
  };

  const handleSelectAllUsers = () => {
    const old = selectAllUsers;
    setSelectAllUsers(!old);
    const _data = { ...data };

    if (old) {
      _data.users = [];
      setData({ ..._data });
      return;
    } else {
      _data.users = usersSearch.map((item) => item.id);
      setData({ ..._data });
      return;
    }
  };

  const handleSearchCampaigns = (val) => {
    setSearchCampaigns(val);
    if (!val) {
      setCampaignsSearch([...campaigns]);
      return;
    }

    const arr = campaigns.filter((item) => {
      let name = item.name;
      name = name.split("");
      name.length = val.length;
      name = name.join("");
      return name.toLowerCase() === val.toLowerCase();
    });

    setCampaignsSearch([...arr]);
  };

  const handleSearchUsers = (val) => {
    setSearchUsers(val);
    if (!val) {
      setUsersSearch([...users]);
      return;
    }

    const arr = users.filter((item) => {
      let name = item.name;
      name = name.split("");
      name.length = val.length;
      name = name.join("");
      return name.toLowerCase() === val.toLowerCase();
    });

    setUsersSearch([...arr]);
  };

  const handleData = (value, target) => {
    const _data = { ...data };
    if (target === "campaigns") {
      const index = _data.campaigns?.length
        ? _data.campaigns.findIndex((item) => item === value)
        : -1;
      if (_data.campaigns === null) _data.campaigns = [value];
      else if (index === -1) _data.campaigns.push(value);
      else _data.campaigns.splice(index, 1);
      _data.campaigns.sort((a, b) => a - b);
    } else if (target === "users") {
      const index = _data.users?.length
        ? _data.users.findIndex((item) => item === value)
        : -1;
      if (_data.users === null) _data.users = [value];
      else if (index === -1) _data.users.push(value);
      else _data.users.splice(index, 1);
      _data.users.sort((a, b) => a - b);
    } else {
      _data[target] = value;
    }

    setData({ ..._data });
  };

  const isCampaigned = (id) => {
    if (!data.campaigns?.length) return false;
    const index = data.campaigns?.findIndex((item) => item === id);
    return index !== -1;
  };

  const isUsered = (id) => {
    if (!data.users?.length) return false;
    const index = data.users?.findIndex((item) => item === id);
    return index !== -1;
  };

  const save = () => {
    callback();
    close();
  };

  return (
    <Backdrop open={open} sx={{ zIndex: "3" }}>
      <ClickAwayListener onClickAway={close}>
        <Paper className={classes.cont}>
          <IconButton className={classes.close} onClick={close}>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>
            Edit Affiliate
          </Typography>

          <Box className={classes.box}>
            <Box className={classes.boxItem}>
              <Typography gutterBottom>Name</Typography>
              <CustomInput value={data.name} disabled sx={{ width: "100%" }} />
            </Box>

            <Box className={classes.boxItem}>
              <Typography gutterBottom>Daily Quota</Typography>
              <CustomInput
                value={data.max_daily}
                sx={{ width: "100%" }}
                callback={(val) => handleData(val, "max_daily")}
              />
            </Box>

            <Box className={classes.boxItem} sx={{ height: "69.59px" }}>
              <Typography gutterBottom>Start traffic</Typography>
              {data.start_traffic ? (
                <CustomInputDate
                  value={data.start_traffic}
                  callback={(val) =>
                    handleData(val + ":00.000000", "start_traffic")
                  }
                />
              ) : null}
            </Box>

            <Box className={classes.boxItem} sx={{ height: "69.59px" }}>
              <Typography gutterBottom>Stop traffic</Typography>
              {data.stop_traffic ? (
                <CustomInputDate
                  value={data.stop_traffic}
                  callback={(val) =>
                    handleData(val + ":00.000000", "stop_traffic")
                  }
                />
              ) : null}
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: "20px",
            }}
          >
            <Button sx={{ width: "45%" }} onClick={() => setTab(true)}>
              Campaigns
            </Button>
            <Button sx={{ width: "45%" }} onClick={() => setTab(false)}>
              Users
            </Button>
          </Box>

          {tab ? (
            <>
              <CustomInput
                placeholder={"Search"}
                callback={handleSearchCampaigns}
                value={searchCampaigns}
                sx={{ width: "100%" }}
              />
              <Box
                className={classes.box}
                sx={{ height: "300px", overflowY: "auto", marginTop: "5px" }}
              >
                <FormGroup sx={{ width: "100%", paddingRight: "20px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectAllCampaigns}
                        onChange={handleSelectAllCampaigns}
                      />
                    }
                    label={"Select All"}
                  />
                  {campaignsSearch?.map((item, i) => (
                    <Box className={classes.listItem}>
                      <FormControlLabel
                        key={`campaignsList${item.id}${i}`}
                        control={
                          <Checkbox
                            checked={isCampaigned(item.id)}
                            onChange={() => handleData(item.id, "campaigns")}
                          />
                        }
                        label={item.name}
                      />
                      <Typography>ID: {item.id}</Typography>
                    </Box>
                  ))}
                </FormGroup>
              </Box>
            </>
          ) : (
            <>
              <CustomInput
                placeholder={"Search"}
                callback={handleSearchUsers}
                value={searchUsers}
                sx={{ width: "100%" }}
              />
              <Box
                className={classes.box}
                sx={{ height: "300px", overflowY: "auto", marginTop: "5px" }}
              >
                <FormGroup sx={{ width: "100%", paddingRight: "20px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectAllUsers}
                        onChange={handleSelectAllUsers}
                      />
                    }
                    label={"Select All"}
                  />
                  {usersSearch?.map((item, i) => (
                    <Box className={classes.listItem}>
                      <FormControlLabel
                        key={`usersList${item.id}${i}`}
                        control={
                          <Checkbox
                            checked={isUsered(item.id)}
                            onChange={() => handleData(item.id, "users")}
                          />
                        }
                        label={item.name}
                      />
                      <Typography>ID: {item.id}</Typography>
                    </Box>
                  ))}
                </FormGroup>
              </Box>
            </>
          )}

          <Button variant="contained" sx={{ width: "100%" }} onClick={save}>
            Сохранить
          </Button>
        </Paper>
      </ClickAwayListener>
    </Backdrop>
  );
}
