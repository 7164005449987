import React from "react";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import CustomSelect from "./CustomSelect";

export default function CustomSeachSelect({
  sx,
  sxInput,
  options,
  value,
  callback,
  disabled,
  placeholder,
}) {
  const defaultProps = {
    options: options,
    getOptionLabel: (option) => option?.title,
  };

  const handle = (e, val) => {
    callback(val?.name);
  };
  return (
    <Autocomplete
      disablePortal
      disabled={disabled}
      {...defaultProps}
      onChange={handle}
      value={value}
      sx={{
        width: 260,
        "& .MuiOutlinedInput-root": {
          padding: "0px 9px",
        },
        backgroundColor: "#F0F0F0",
        borderRadius: "10px",
        maxHeight: "36px",
        ...sx,
      }}
      placeholder={placeholder}
      renderInput={(params) => (
        <TextField {...params} sx={{ ...sxInput }} placeholder={placeholder} />
      )}
    />
  );
}
