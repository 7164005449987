import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { visuallyHidden } from "@mui/utils";
import { Button, Popover } from "@mui/material";
import PopoverContentFilters from "./PopoverContentFilters";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { colors } from "../config/colors";
import PopoverContentDate from "./PopoverContentDate";
import KZ from "../png/kz.png";
import FR from "../png/freerf.png";
import TR from "../png/trk.png";
import PopoverContentId from "./PopoverContentId";
import SearchIcon from "@mui/icons-material/Search";
import PopoverFiltersSearch from "./PopoverFiltersSearch";
import Loader from "./Loader";

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    heads,
    exclude,
    onRequestSort,
    filters,
    openFiltred,
    handleOpenFiltred,
    handleFiltred,
    resetFiltred,
    fetchedFilters,
    closeFiltred,
    role,
    loading,
    dataLength,
  } = props;
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{ display: loading || !dataLength ? "flex" : "table-header-group" }}
    >
      <TableRow>
        {role === "admin" ? (
          <TableCell
            padding="checkbox"
            className={classes.cell}
            sx={{ paddingLeft: "10px" }}
          >
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        ) : null}
        {heads.map((headCell) =>
          exclude.findIndex((exc) => exc === headCell.id) === -1 ? (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.cell}
            >
              <Box sx={{ display: "flex" }}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
                {headCell.id === "status" ? (
                  <>
                    <IconButton
                      sx={{ padding: "0px" }}
                      onClick={(e) => handleOpenFiltred(e, "status")}
                    >
                      <FilterAltIcon
                        sx={{
                          color:
                            filters.findIndex(
                              (item) => item.name === "status"
                            ) === -1
                              ? "rgba(0, 0, 0, 0.54)"
                              : "#4669FA",
                        }}
                      />
                    </IconButton>
                    <Popover
                      open={Boolean(openFiltred.status)}
                      onClose={() => handleOpenFiltred(null, "status")}
                      anchorEl={openFiltred.status}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box sx={{ display: "flex", padding: "20px" }}>
                        <PopoverContentFilters
                          filtersVariants={fetchedFilters.status}
                          callback={(value) => handleFiltred("status", value)}
                          close={() => closeFiltred("status")}
                          reset={() => resetFiltred("status")}
                          filters={filters}
                          target="status"
                        />
                      </Box>
                    </Popover>
                  </>
                ) : headCell.id === "campaign" ? (
                  <>
                    <IconButton
                      sx={{ padding: "0px" }}
                      onClick={(e) => handleOpenFiltred(e, "campaign")}
                    >
                      <FilterAltIcon
                        sx={{
                          color:
                            filters.findIndex(
                              (item) => item.name === "campaign"
                            ) === -1
                              ? "rgba(0, 0, 0, 0.54)"
                              : "#4669FA",
                        }}
                      />
                    </IconButton>
                    <Popover
                      open={Boolean(openFiltred.campaign)}
                      onClose={() => handleOpenFiltred(null, "campaign")}
                      anchorEl={openFiltred.campaign}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box sx={{ display: "flex", padding: "20px" }}>
                        <PopoverContentFilters
                          filtersVariants={fetchedFilters.campaign}
                          callback={(value) => handleFiltred("campaign", value)}
                          close={() => closeFiltred("campaign")}
                          reset={() => resetFiltred("campaign")}
                          filters={filters}
                          target="campaign"
                        />
                      </Box>
                    </Popover>
                  </>
                ) : headCell.id === "affiliate" ? (
                  <>
                    <IconButton
                      sx={{ padding: "0px" }}
                      onClick={(e) => handleOpenFiltred(e, "affiliate")}
                    >
                      <FilterAltIcon
                        sx={{
                          color:
                            filters.findIndex(
                              (item) => item.name === "affiliate"
                            ) === -1
                              ? "rgba(0, 0, 0, 0.54)"
                              : "#4669FA",
                        }}
                      />
                    </IconButton>
                    <Popover
                      open={Boolean(openFiltred.affiliate)}
                      onClose={() => handleOpenFiltred(null, "affiliate")}
                      anchorEl={openFiltred.affiliate}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box sx={{ display: "flex", padding: "20px" }}>
                        <PopoverContentFilters
                          filtersVariants={fetchedFilters.affiliate}
                          callback={(value) =>
                            handleFiltred("affiliate", value)
                          }
                          close={() => closeFiltred("affiliate")}
                          reset={() => resetFiltred("affiliate")}
                          filters={filters}
                          target="affiliate"
                        />
                      </Box>
                    </Popover>
                  </>
                ) : headCell.id === "user" ? (
                  <>
                    <IconButton
                      sx={{ padding: "0px" }}
                      onClick={(e) => handleOpenFiltred(e, "user")}
                    >
                      <FilterAltIcon
                        sx={{
                          color:
                            filters.findIndex(
                              (item) => item.name === "user"
                            ) === -1
                              ? "rgba(0, 0, 0, 0.54)"
                              : "#4669FA",
                        }}
                      />
                    </IconButton>
                    <Popover
                      open={Boolean(openFiltred.user)}
                      onClose={() => handleOpenFiltred(null, "user")}
                      anchorEl={openFiltred.user}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box sx={{ display: "flex", padding: "20px" }}>
                        <PopoverContentFilters
                          filtersVariants={fetchedFilters.user}
                          callback={(value) => handleFiltred("user", value)}
                          close={() => closeFiltred("user")}
                          reset={() => resetFiltred("user")}
                          filters={filters}
                          target="user"
                        />
                      </Box>
                    </Popover>
                  </>
                ) : headCell.id === "created_at" ? (
                  <>
                    <IconButton
                      sx={{ padding: "0px" }}
                      onClick={(e) => handleOpenFiltred(e, "created_at")}
                    >
                      <CalendarMonthIcon
                        sx={{
                          color:
                            filters.findIndex(
                              (item) => item.name === "created_at"
                            ) === -1
                              ? "rgba(0, 0, 0, 0.54)"
                              : "#4669FA",
                        }}
                      />
                    </IconButton>
                    <Popover
                      open={Boolean(openFiltred.created_at)}
                      onClose={() => handleOpenFiltred(null, "created_at")}
                      anchorEl={openFiltred.created_at}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: "20px",
                        }}
                      >
                        <PopoverContentDate
                          filters={filters}
                          callback={(value) =>
                            handleFiltred("created_at", value)
                          }
                          close={() => closeFiltred("created_at")}
                          reset={() => resetFiltred("created_at")}
                          target={"created_at"}
                        />
                      </Box>
                    </Popover>
                  </>
                ) : headCell.id === "send_date" ? (
                  <>
                    <IconButton
                      sx={{ padding: "0px" }}
                      onClick={(e) => handleOpenFiltred(e, "send_date")}
                    >
                      <CalendarMonthIcon
                        sx={{
                          color:
                            filters.findIndex(
                              (item) => item.name === "send_date"
                            ) === -1
                              ? "rgba(0, 0, 0, 0.54)"
                              : "#4669FA",
                        }}
                      />
                    </IconButton>
                    <Popover
                      open={Boolean(openFiltred.send_date)}
                      onClose={() => handleOpenFiltred(null, "send_date")}
                      anchorEl={openFiltred.send_date}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: "20px",
                        }}
                      >
                        <PopoverContentDate
                          filters={filters}
                          callback={(value) =>
                            handleFiltred("send_date", value)
                          }
                          close={() => closeFiltred("send_date")}
                          reset={() => resetFiltred("send_date")}
                          target={"send_date"}
                        />
                      </Box>
                    </Popover>
                  </>
                ) : headCell.id === "id" ? (
                  <>
                    <IconButton
                      sx={{ padding: "0px" }}
                      onClick={(e) => handleOpenFiltred(e, "id")}
                    >
                      <SearchIcon
                        sx={{
                          color:
                            filters.findIndex((item) => item.name === "id") ===
                            -1
                              ? "rgba(0, 0, 0, 0.54)"
                              : "#4669FA",
                        }}
                      />
                    </IconButton>
                    <Popover
                      open={Boolean(openFiltred.id)}
                      onClose={() => handleOpenFiltred(null, "id")}
                      anchorEl={openFiltred.id}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: "20px",
                        }}
                      >
                        <PopoverContentId
                          callback={(value) => handleFiltred("id", value)}
                          close={() => closeFiltred("id")}
                          reset={()=> resetFiltred("id")}
                          filters={filters}
                        />
                      </Box>
                    </Popover>
                  </>
                ) : headCell.id === "name" ? (
                  <>
                    <IconButton
                      sx={{ padding: "0px" }}
                      onClick={(e) => handleOpenFiltred(e, "name")}
                    >
                      <SearchIcon
                        sx={{
                          color:
                            filters.findIndex(
                              (item) => item.name === "name"
                            ) === -1
                              ? "rgba(0, 0, 0, 0.54)"
                              : "#4669FA",
                        }}
                      />
                    </IconButton>
                    <Popover
                      open={Boolean(openFiltred.name)}
                      onClose={() => handleOpenFiltred(null, "name")}
                      anchorEl={openFiltred.name}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: "20px",
                        }}
                      >
                        <PopoverFiltersSearch
                          callback={(value, compare) =>
                            handleFiltred("name", value, compare)
                          }
                          close={() => closeFiltred("name")}
                          reset={()=> resetFiltred("name")}
                          target="name"
                          filters={filters}
                        />
                      </Box>
                    </Popover>
                  </>
                ) : headCell.id === "email" ? (
                  <>
                    <IconButton
                      sx={{ padding: "0px" }}
                      onClick={(e) => handleOpenFiltred(e, "email")}
                    >
                      <SearchIcon
                        sx={{
                          color:
                            filters.findIndex(
                              (item) => item.name === "email"
                            ) === -1
                              ? "rgba(0, 0, 0, 0.54)"
                              : "#4669FA",
                        }}
                      />
                    </IconButton>
                    <Popover
                      open={Boolean(openFiltred.email)}
                      onClose={() => handleOpenFiltred(null, "email")}
                      anchorEl={openFiltred.email}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: "20px",
                        }}
                      >
                        <PopoverFiltersSearch
                          callback={(value, compare) =>
                            handleFiltred("email", value, compare)
                          }
                          close={() => closeFiltred("email")}
                          reset={()=> resetFiltred("email")}
                          target="email"
                          filters={filters}
                        />
                      </Box>
                    </Popover>
                  </>
                ) : headCell.id === "source" ? (
                  <>
                    <IconButton
                      sx={{ padding: "0px" }}
                      onClick={(e) => handleOpenFiltred(e, "source")}
                    >
                      <SearchIcon
                        sx={{
                          color:
                            filters.findIndex(
                              (item) => item.name === "source"
                            ) === -1
                              ? "rgba(0, 0, 0, 0.54)"
                              : "#4669FA",
                        }}
                      />
                    </IconButton>
                    <Popover
                      open={Boolean(openFiltred.source)}
                      onClose={() => handleOpenFiltred(null, "source")}
                      anchorEl={openFiltred.source}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: "20px",
                        }}
                      >
                        <PopoverFiltersSearch
                          callback={(value, compare) =>
                            handleFiltred("source", value, compare)
                          }
                          close={() => closeFiltred("source")}
                          reset={()=> resetFiltred("source")}
                          target="source"
                          filters={filters}
                        />
                      </Box>
                    </Popover>
                  </>
                ) : headCell.id === "phone" ? (
                  <>
                    <IconButton
                      sx={{ padding: "0px" }}
                      onClick={(e) => handleOpenFiltred(e, "phone")}
                    >
                      <SearchIcon
                        sx={{
                          color:
                            filters.findIndex(
                              (item) => item.name === "phone"
                            ) === -1
                              ? "rgba(0, 0, 0, 0.54)"
                              : "#4669FA",
                        }}
                      />
                    </IconButton>
                    <Popover
                      open={Boolean(openFiltred.phone)}
                      onClose={() => handleOpenFiltred(null, "phone")}
                      anchorEl={openFiltred.phone}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          padding: "20px",
                        }}
                      >
                        <PopoverFiltersSearch
                          callback={(value, compare) =>
                            handleFiltred("phone", value, compare)
                          }
                          close={() => closeFiltred("phone")}
                          reset={()=> resetFiltred("phone")}
                          target="phone"
                          filters={filters}
                        />
                      </Box>
                    </Popover>
                  </>
                ) : null}
              </Box>
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  row: {
    "& .MuiTableCell-root": {
      padding: "10px",
      borderBottom: "1px solid #d2d2d2",
    },
    transition: theme.transitions.create(["background-color", "transform"], {
      duration: theme.transitions.duration.standard,
    }),
  },
  cell: {
    backgroundColor: "rgb(241, 243, 247)!important",
  },
  collapseBox: {
    height: "45px",
    overflowY: "hidden",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    textAlign: "start",
    width:"250px",
    cursor:"pointer",
    position:"relative",
    "&::after":{
      content: '"..."',
        display: 'block',
        position:"absolute",
        bottom:"-12px",
        fontSize:"30px",
        color:"#4669FA"
    }
  },
  collapseBoxOpen: {
    height: "fit-content",
    overflowY: "hidden",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    textAlign: "start",
    width:"250px",
    cursor:"pointer",
  },
  contEmpty: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "calc(100vh - 307px)",
  },
}));

export default function CustomTable({
  data,
  order,
  orderBy,
  onRequestSort,
  heads,
  selected,
  exclude,
  filters,
  openFiltred,
  handleOpenFiltred,
  handleFiltred,
  fetchedFilters,
  resetFiltred,
  closeFiltred,
  role,
  handleSelectAllClick,
  isSelected,
  handleClick,
  newLeads,
  handleNewLead,
  loading,
  dataLength,
  resetFilters,
  handleOnUserClick,
}) {
  const classes = useStyles();
  // const getColorIndex = (status) => {
  //   const index = fetchedFilters.status.findIndex(
  //     (item) => item.name === status
  //   );
  //   const res = colors.length > index ? index : "#FB79BF";
  //   return res;
  // };

  return (
    <>
      <TableContainer
        onMouseEnter={handleNewLead}
        sx={{ height: "calc(100vh - 220px)", overflowY: "visible" }}
      >
        {loading 
          ?
            <Loader sx={{ height: "calc(100vh - 307px)" }} />
          : 
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
              stickyHeader
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={onRequestSort}
                onSelectAllClick={handleSelectAllClick}
                rowCount={data.length}
                heads={heads}
                exclude={exclude}
                filters={filters}
                openFiltred={openFiltred}
                handleOpenFiltred={handleOpenFiltred}
                handleFiltred={handleFiltred}
                resetFiltred={resetFiltred}
                fetchedFilters={fetchedFilters}
                closeFiltred={closeFiltred}
                role={role}
                loading={loading}
                dataLength={dataLength}
              />
              {dataLength ? (
                <TableBody sx={{ overflowX: "scroll" }}>
                  {data.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const isNewLead =
                      newLeads.findIndex((item) => item === row.id) !== -1;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        className={classes.row}
                      >
                        {role === "admin" ? (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              onClick={(event) => handleClick(event, row.id)}
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "id") === -1 ? (
                          <TableCell>
                            <Link onClick={() => handleOnUserClick(row.id)}>{row.id}</Link>
                          </TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "country") === -1 ? (
                          <TableCell>
                            {row.country.toLowerCase() === "ru" ? (
                              <img src={FR} style={{ width: "20px" }} />
                            ) : row.country.toLowerCase() === "kz" ? (
                              <img src={KZ} style={{ width: "20px" }} />
                            ) : <img src={TR} style={{ width: "20px" }} />
                            }
                          </TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "name") === -1 ? (
                          <TableCell>
                            <Link onClick={() => handleOnUserClick(row.id)}>{row.name}</Link>
                          </TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "email") === -1 ? (
                          <TableCell>{row.email}</TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "phone") === -1 ? (
                          <TableCell>{row.phone}</TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "status") === -1 ? (
                          <TableCell>
                            <div
                              style={{
                                padding: "5px 11px",
                                borderRadius: "10px",
                                backgroundColor: colors[row.status],
                                width: "fit-content",
                                color:
                                  colors[row.status] === "#000" ? "white" : "black",
                              }}
                            >
                              {row.status}
                            </div>
                          </TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "campaign") === -1 ? (
                          <TableCell>{row.campaign}</TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "affiliate") === -1 ? (
                          <TableCell>{row.affiliate}</TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "landing") === -1 ? (
                          <TableCell>{row.landing ? row.landing : 'N/A'}</TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "timezone") === -1 ? (
                          <TableCell>{row.timezone}</TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "desc") === -1 ? (
                          <TableCell>
                            <div
                              className={row.desc === "N/A" ? null : classes.collapseBox}
                              onClick={row.desc === "N/A" ? null : (e) => {
                                if(e.target.classList.contains(classes.collapseBox)){
                                  e.target.classList.remove(classes.collapseBox);
                                  e.target.classList.add(classes.collapseBoxOpen);
                                }
                                else{
                                  e.target.classList.remove(classes.collapseBoxOpen);
                                  e.target.classList.add(classes.collapseBox);
                                }
                              }}
                            >
                              {row.desc}
                            </div>
                          </TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "comment") === -1 ? (
                            <TableCell>
                              <div
                              className={row.comment === "N/A" ? null : classes.collapseBox}
                              onClick={row.comment === "N/A" ? null : (e) => {
                                if(e.target.classList.contains(classes.collapseBox)){
                                  e.target.classList.remove(classes.collapseBox);
                                  e.target.classList.add(classes.collapseBoxOpen);
                                }
                                else{
                                  e.target.classList.remove(classes.collapseBoxOpen);
                                  e.target.classList.add(classes.collapseBox);
                                }
                              }}
                            >
                              {row.comment}
                            </div>
                            </TableCell>
                        ) : null}

                        {exclude.findIndex((exc) => exc === "source") === -1 ? (
                          <TableCell>
                            {row.source.split("").indexOf(".") !== -1 ? (
                              <a
                                href={`https://${row.source}`}
                                target="_blank"
                                style={{
                                  color: "black",
                                  textDecoration: "underline !important",
                                }}
                              >
                                {row.source}
                              </a>
                            ) : (
                              row.source
                            )}
                          </TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "user") === -1 ? (
                          <TableCell>{row.user}</TableCell>
                        ) : null}

                        {exclude.findIndex((exc) => exc === "created_at") === -1 ? (
                          <TableCell>{row.created_at}</TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "send_date") === -1 ? (
                          <TableCell>{row.send_date}</TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "custom_param1") ===
                        -1 ? (
                          <TableCell>{row.custom_param1}</TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "custom_param2") ===
                        -1 ? (
                          <TableCell>{row.custom_param2}</TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "custom_param3") ===
                        -1 ? (
                          <TableCell>{row.custom_param3}</TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "custom_param4") ===
                        -1 ? (
                          <TableCell>{row.custom_param4}</TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "custom_param5") ===
                        -1 ? (
                          <TableCell>{row.custom_param5}</TableCell>
                        ) : null}
                        {exclude.findIndex((exc) => exc === "custom_param6") ===
                        -1 ? (
                          <TableCell>{row.custom_param6}</TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <Box className={classes.contEmpty}>
                  Nothing for your request{"(((((("}
                  <Button
                    onClick={resetFilters}
                    variant="contained"
                    sx={{
                      minWidth: "150px",
                      height: "36.5px",
                      marginTop: "20px",
                    }}
                  >
                    Reset filters
                  </Button>
                </Box>
              )}
            </Table>
        }
      </TableContainer>
    </>
  );
}
