const url = "https://api.catoftree.com";

export const fetchAuthorized = async (token) => {
    const result = await fetch(`${url}/authorized`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchLeads = async (token, data) => {
    const result = await fetch(`${url}/leads?${data}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchLeadRedirect = async (token, data) => {
    const result = await fetch(`${url}/leads/redirect`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchLead = async (token, data) => {
    const result = await fetch(`${url}/lead/${data}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchLeadLogs = async (token, data) => {
    const result = await fetch(`${url}/lead/log/${data}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchLeadFraudInfo = async (token, data) => {
    const result = await fetch(`${url}/lead/log/fraud/${data}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchAffiliates = async (token, data = '') => {
    const result = await fetch(`${url}/affiliate?${data}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchStats = async (token, data) => {
    const result = await fetch(`${url}/statistic?start_date=${data.start}&end_date=${data.end}${data.user ? "&user_id=" + data.user : ""}&country_id=${data?.country_id}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchStatsMain = async (token, data) => {
    const result = await fetch(`${url}/statistic/main?start_date=${data.start}&end_date=${data.end}${data.user ? "&user_id=" + data.user : ""}&country_id=${data?.country_id}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchStatsAffiliates = async (token, data) => {
    const result = await fetch(`${url}/statistic/affiliates?start_date=${data.start}&end_date=${data.end}${data.user ? "&user_id=" + data.user : ""}&country_id=${data?.country_id}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchStatsCampaigns = async (token, data) => {
    const result = await fetch(`${url}/statistic/campaigns?start_date=${data.start}&end_date=${data.end}${data.user ? "&user_id=" + data.user : ""}&country_id=${data?.country_id}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchKeys = async (token) => {
    const result = await fetch(`${url}/user/keys`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchHold = async (token, data) => {
    const result = await fetch(`${url}/statistic/hold?country_id=${data?.country_id}${data.id ? "&user_id=" + data.id : ""}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchGenerateKey = async (token, data) => {
    const result = await fetch(`${url}/user/key/new`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchUpdateKey = async (token, data) => {
    const result = await fetch(`${url}/user/key/update`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}


export const fetchDeleteKey = async (token, data) => {
    const result = await fetch(`${url}/user/key/delete`, {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchUpdateStatus = async (token, data) => {
    const result = await fetch(`${url}/affiliate/update`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchUpdateAffiliate = async (token, data) => {
    const result = await fetch(`${url}/affiliate/update`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchLogin = async (data) => {
    const result = await fetch(`${url}/login`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchFiltersCampaigns = async (token) => {
    const result = await fetch(`${url}/data/campaigns`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchFiltersAffiliates = async (token) => {
    const result = await fetch(`${url}/data/affiliates`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchFiltersStatuses = async (token) => {
    const result = await fetch(`${url}/data/status`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchSavedFilters = async (token) => {
    const result = await fetch(`${url}/user/filters`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchUsers = async (token) => {
    const result = await fetch(`${url}/users`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchNewFilter = async (token, data) => {
    const result = await fetch(`${url}/user/filters/new`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchUpdateFilter = async (token, data) => {
    const result = await fetch(`${url}/user/filters/update`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchDeleteFilter = async (token, data) => {
    const result = await fetch(`${url}/user/filters/delete`, {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchCancelLeads = async (token, data) => {
    const result = await fetch(`${url}/leads/cancel `, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchDeleteLeads = async (token, data) => {
    const result = await fetch(`${url}/leads/delete `, {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchAdminUsers = async (token) => {
    const result = await fetch(`${url}/admin/users`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchAdminUserUpdate = async (token, data) => {
    const result = await fetch(`${url}/admin/user/update`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => res.json())
        .then(data => data);

    return result;
}

export const fetchAdminUserCreate = async (token, data) => {
    const result = await fetch(`${url}/admin/user/create`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => res.json())
        .then(data => data);

    return result;
}

export const fetchCountries = async (token) => {
    const result = await fetch(`${url}/data/country`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
} 

export const fetchNewCountry = async (token, data) => {
    const result = await fetch(`${url}/admin/country/create`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;
        });
    return result;
}

export const getDomainDns = async (token) => {
    const result = await fetch(`${url}/domains/dns`, {
        method: 'GET',
        headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;
        });

    return result;
}

export const getDomains = async (token) => {
    const result = await fetch(`${url}/domains/get`, {
        method: 'GET',
        headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;
        });

    return result;
}

export const fetchCheckDomains = async (token, data) => {
    const result = await fetch(`${url}/domains/check`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;
        });

    return result;
}

export const fetchBuyDomains = async (token, data) => {
    const result = await fetch(`${url}/domains/buy`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;
        });

    return result;
}