import { useState, useEffect } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { fetchFiltersAffiliates, fetchLeadLogs } from "../api/api";
import { getTokenValue } from "../config/cookie";
import GlobalLoader from "./GlobalLoader";
import { logsHeadCells } from "../config/heads";

export default function LogsInfo({ id }) {
  const [affiliates, setAffiliates] = useState([]);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAffiliate = (aff) => {
    const _aff = affiliates.find((item) => item.id === +aff);
    return _aff.name;
  };

  const getLeadLogs = async () => {
    try {
      const res = await fetchFiltersAffiliates(getTokenValue());
      if (res.success) {
        setAffiliates([...res.data]);
      } else {
        setSnack({
          text: res.message ? res.message : "Error",
          type: "error",
          open: true,
        });
      }

      const res2 = await fetchLeadLogs(getTokenValue(), id);
      if (res2.success) {
        setLogs([...res2.data]);
      } else {
        setSnack({
          text: res2.message ? res2.message : "Error",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLeadLogs()
  }, [id]);

  return (
    <>
      {loading ? <GlobalLoader style={{ height: "300px" }} /> :
        logs.length ? (
        <TableContainer component={Paper} className="herehere">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {logsHeadCells.map((item, i) => (
                  <TableCell align={i === 0 ? "left" : "right"} key={i}>
                    {item.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((row) => (
                <TableRow
                  key={row.affiliate}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {getAffiliate(row.affiliate)}
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px" }}>
                    {row.created_at}
                  </TableCell>
                  <TableCell align="right" sx={{ minWidth: "150px" }}>
                    {row.status ? "Отправлено" : "Не отправлено"}
                  </TableCell>
                  <TableCell align="left">
                    {JSON.stringify(row.response)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        ) : <p style={{ fontSize: "1.25rem", fontWeight: "500", lineHeight: "2rem", color: "hsl(232 14% 68%)", textAlign: "center" }}>The lead has no logs</p>
      }
    </>
  );
}
