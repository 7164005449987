import React from "react";
import { TextField } from "@mui/material";

export default function CustomInput({
  sx,
  type,
  value,
  callback,
  disabled,
  placeholder,
  blur,
}) {
  const handle = (e) => {
    callback && callback(e.target.value);
  };
  return (
    <TextField
      disabled={disabled}
      color="primary"
      placeholder={placeholder ? placeholder : ""}
      size="small"
      variant="outlined"
      type={type ? type : "text"}
      value={value}
      sx={{
        minWidth: "150px",
        "& .MuiInputBase-input": {
          padding: "6.5px 14px",
        },
        ...sx,
      }}
      onChange={handle}
      onBlur={blur ? (e) => blur(e.target.value) : null}
    />
  );
}
