import { useEffect, useState } from "react";
import { getTokenValue } from "../../../config/cookie";
import { getDomains } from "../../../api/api";

const useGetDomains = async () => {
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    const token = getTokenValue();
    const fetchDomains = async () => {
      const res = await getDomains(token);
      if (res.success) setDomains([...res.data]);
    }
    fetchDomains();
  }, []);

  return { domains };
}

export default useGetDomains;