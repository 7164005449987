import Cookies from 'js-cookie';

export const getToken = () => {
    return !!Cookies.get('token') && !Cookies.get('token').includes("undefined");
}

export const getTokenValue = () => {
    return Cookies.get('token');
}

export const getRole = () => {
    return Cookies.get('role');
}

export const setToken = (token) => {
    let dateMili = Date.now();
    const date = new Date(dateMili + 86400000);
    return Cookies.set('token', token, { path: '/', sameSite: 'strict', expires: date });
}

export const setRoleCookie = (role) => {
    let dateMili = Date.now();
    const date = new Date(dateMili + 86400000);
    return Cookies.set('role', role, { path: '/', sameSite: 'strict', expires: date });
}

export const unSetToken = () => {
    return Cookies.remove('token');
}