import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";

export const predefinedRanges = [
    {
        label: "Today",
        value: [new Date(), new Date()],
        placement: "left",
    },
    {
        label: "Yesterday",
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
        placement: "left",
    },
    {
        label: "This week",
        value: [startOfWeek(new Date()), endOfWeek(new Date())],
        placement: "left",
    },
    {
        label: "Last 7 days",
        value: [subDays(new Date(), 6), new Date()],
        placement: "left",
    },
    {
        label: "Last 30 days",
        value: [subDays(new Date(), 29), new Date()],
        placement: "left",
    },
    {
        label: "This month",
        value: [startOfMonth(new Date()), new Date()],
        placement: "left",
    },
    {
        label: "Last month",
        value: [
            startOfMonth(addMonths(new Date(), -1)),
            endOfMonth(addMonths(new Date(), -1)),
        ],
        placement: "left",
    },
    {
        label: "All time",
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
        placement: "left",
    },
];