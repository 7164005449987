export function validateDomainsSuffix(domains) {
  const suffix = domains[0].split('.').pop().replace(/[^a-zа-яё]/gi, '');

  if (suffix === 'ru' || suffix === 'рф') {
    return domains.every((domain) => {
      return domain.split('.').pop() === 'ru' || domain.split('.').pop() === 'рф';
    });
  }

	return domains.every((domain) => {
		return domain.split('.').pop() !== 'ru' && domain.split('.').pop() !== 'рф';
	});
}