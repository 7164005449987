const createOrObj = (arr, i2) => {
    const or = {
        type: "or",
        components: [],
    };
    let index;
    for (let i = i2; i < arr.length; i++) {
        or.components.push(arr[i]);
        if (!arr[i + 1]) {
            index = i + 1;
            break;
        }
        if (!arr[i + 1].compare) {
            or.components.push(arr[i + 1]);
            index = i + 1;
            break;
        }
    }
    return [or, index];
};

export const constructFilters = (pFilters) => {

    const arr = [];
    if (pFilters.length === 1) {
        const [or, index] = createOrObj(pFilters, 0);
        arr.push(or);
        return arr;
    }

    for (let i = 0; i < pFilters.length;) {
        if (i === 0 && !pFilters[i].compare) {
            arr.push(pFilters[i]);
            if (pFilters[i + 1]?.compare) {
            }
            if (!pFilters[i + 1]?.compare) {
                arr.push(pFilters[i + 1]);
            }
            i++;
            continue;
        }
        if (i === pFilters.length - 1) {
            i++;
            continue;
        }
        if (pFilters[i].compare) {
            let [or, index] = createOrObj(pFilters, i);
            arr.push(or);
            i = index;
            continue;
        } else {
            if (!pFilters[i + 1].compare) {
                arr.push(pFilters[i + 1]);
                i++;
                continue;
            } else {
                i++;
                continue;
            }
        }
    }
    
    return arr;
};