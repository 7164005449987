import * as React from 'react';
import { createBrowserRouter, RouterProvider, useNavigate, useLocation } from "react-router-dom";
import Layout from './Components/Layout';
import Platform from './Pages/Platform/Platform';
import NoMatch from './Components/NoMatch';
import Profile from './Pages/Profile/Profile';
import { Alert, Box, Snackbar } from '@mui/material';
import { useState } from 'react';
import Login from './Pages/Login/Login';
import { useEffect } from 'react';
import { getRole, getToken, getTokenValue, unSetToken } from './config/cookie';
import Lead from './Pages/Platform/Lead';
import "./App.css";
import Affiliates from './Pages/Affiliates/Affiliates';
import Stats from './Pages/Stats/Stats';
import Keys from './Pages/Keys/Keys';
import UserList from './Pages/UserList/UserList';
import Domains from './Pages/Domains/Domains';
import { fetchAuthorized } from './api/api';
import { useRef } from 'react';

const INITIAL_TIMER = 120;

Date.prototype.toJSON = function () {
  const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
  const properDate = new Date(this);
  properDate.setHours(hoursDiff);
  return properDate.toISOString();
};

export default function App() {


  const [snack, setSnack] = useState({
    text: "",
    open: false,
    type: ""
  });

  const [isLogin, setIsLogin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState("");

  const [timer, setTimer] = useState(INITIAL_TIMER);
  const interval = useRef();

  function handleTimer() {
    interval.current = setInterval(() => {
      setTimer((count) => count - 1);
    }, 1000);
  }

  useEffect(() => {
    if (timer <= 0 && interval.current) {
      checkAuth();
      setTimer(INITIAL_TIMER);
    }
  }, [timer]);

  const checkAuth = async () => {
    try {
      const res = await fetchAuthorized(getTokenValue());
      if (!res.success) {
        unSetToken();
        setIsLogin(false);
        setSnack({
          text: res.message ? res.message : "Unauthenticated",
          type: "error",
          open: true,
        });
      }
    } catch (err) {
      setSnack({
        text: err,
        type: "error",
        open: true,
      });
    }
  }

  useEffect(() => {

    handleTimer();

    if (getRole()) setRole(getRole());

    if (getToken()) {
      setIsLogin(true);
    }
    else {
      setIsLogin(false);
    }
    setLoading(false)
  }, [])


  const router = createBrowserRouter([
    {
      path: "/",
      element:
        <RequireAuth isLogin={isLogin} loading={loading}>
          <Layout loading={loading} setIsLogin={setIsLogin} role={role} />
        </RequireAuth>,
      children: [
        {
          index: true,
          element: <Platform setSnack={setSnack} role={role} />
        },
        {
          path: "lead/:id",
          element: <Lead setSnack={setSnack} />
        },
        {
          path: "affiliates",
          element: role === "admin" ? <Affiliates setSnack={setSnack} /> : null
        },
        {
          path: "statistics",
          element: <Stats setSnack={setSnack} />
        },
        {
          path: "user-keys",
          element: <Keys setSnack={setSnack} />
        },
        {
          path: "user-list",
          element: role === "admin" ? <UserList setSnack={setSnack} /> : null
        },
        {
          path: "domains",
          element: <Domains setSnack={setSnack} />
        },
        {
          path: "profile",
          element: <Profile />
        },
        {
          path: "*",
          element: <NoMatch />,
        },
      ],
    },
    {
      path: "/login",
      element: <RequireAuth isLogin={isLogin} loading={loading}><Login setLogined={setIsLogin} setSnack={setSnack} setRole={setRole} /></RequireAuth>
    }
  ]);




  const handleClose = () => {
    setSnack({
      ...snack,
      open: false
    })
  }

  return (
    <>
      <Snackbar open={snack.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snack.type ? snack.type : "success"} sx={{ width: '100%' }}>
          {snack.text}
        </Alert>
      </Snackbar>
      <Box sx={{ backgroundColor: "#e5e7eb" }}>
        <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
      </Box>
    </>
  );
}



function RequireAuth({ isLogin, loading, children }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (loading) return;
    isLogin && location.pathname === "/login" ? navigate("/") : !isLogin && location.pathname !== "/login" ? navigate("/login") : null;
  }, [isLogin, location, loading]);

  return children;
}
