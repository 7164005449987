import React from "react";
import { MenuItem, Select } from "@mui/material";

export default function CustomSelect({
  sx,
  options,
  value,
  callback,
  placeholder,
  disabled,
}) {
  const handle = (e) => {
    callback(e.target.value);
  };
  return (
    <Select
      value={value}
      color="primary"
      size="small"
      displayEmpty
      disabled={disabled}
      renderValue={(selected) => {
        if (selected?.length === 0) {
          return <>{placeholder}</>;
        }
        const val = options?.find((item) => item.name === value)?.title;
        return val;
      }}
      sx={{
        minWidth: "150px",
        "& .MuiSelect-select": {
          padding: "6.5px 14px",
        },
        backgroundColor: "#F0F0F0",
        ...sx,
      }}
      onChange={handle}
    >
      {options?.map((item, i) => (
        <MenuItem key={`${item.title}${i}`} value={item.name}>
          {item.title}
        </MenuItem>
      ))}
    </Select>
  );
}
