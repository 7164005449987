export const filterSettings = {
    id: {
        types: [
            {
                name: "less",
                title: "Меньше",
            },
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "greater",
                title: "Больше",
            },
        ],
        values: [],
    },
    country: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            }
        ],
        values: [],
    },
    name: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "contain",
                title: "Содержит",
            },
        ],
        values: [],
    },
    user: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "contain",
                title: "Содержит",
            },
        ],
        values: [],
    },
    email: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "contain",
                title: "Содержит",
            },
        ],
        values: [],
    },
    phone: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "contain",
                title: "Содержит",
            },
        ],
        values: [],
    },
    status: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
        ],
        values: [],
    },
    campaign: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
        ],
        values: [],
    },
    affiliate: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
        ],
        values: [],
    },
    timezone: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "contain",
                title: "Содержит",
            },
        ],
        values: [],
    },
    desc: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "contain",
                title: "Содержит",
            },
        ],
        values: [],
    },
    source: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "contain",
                title: "Содержит",
            },
        ],
        values: [],
    },
    created_at: {
        types: [
            {
                name: "less",
                title: "До",
            },
            {
                name: "greater",
                title: "С",
            },
        ],
        values: [],
    },
    send_date: {
        types: [
            {
                name: "less",
                title: "До",
            },
            {
                name: "greater",
                title: "С",
            },
        ],
        values: [],
    },
    custom_param1: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "contain",
                title: "Содержит",
            },
        ],
        values: [],
    },
    custom_param2: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "contain",
                title: "Содержит",
            },
        ],
        values: [],
    },
    custom_param3: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "contain",
                title: "Содержит",
            },
        ],
        values: [],
    },
    custom_param4: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "contain",
                title: "Содержит",
            },
        ],
        values: [],
    },
    custom_param5: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "contain",
                title: "Содержит",
            },
        ],
        values: [],
    },
    custom_param6: {
        types: [
            {
                name: "equal",
                title: "Равно",
            },
            {
                name: "not_equal",
                title: "Не равно",
            },
            {
                name: "contain",
                title: "Содержит",
            },
        ],
        values: [],
    }
};

export const filterNames = [
    {
        name: "id",
        title: "Фильтр по ID",
    },
    {
        name: "country",
        title: "Фильтр по Стране",
    },
    {
        name: "name",
        title: "Фильтр по Имени",
    },
    {
        name: "email",
        title: "Фильтр по Почте",
    },
    {
        name: "phone",
        title: "Фильтр по Телефону",
    },
    {
        name: "status",
        title: "Фильтр по Статусу",
    },
    {
        name: "campaign",
        title: "Фильтр по Кампейну",
    },
    {
        name: "affiliate",
        title: "Фильтр по Покупателю",
    },
    {
        name: "timezone",
        title: "Фильтр по Времени",
    },
    {
        name: "desc",
        title: "Фильтр по Описанию",
    },
    {
        name: "source",
        title: "Фильтр по Источнику",
    },
    {
        name: "user",
        title: "Фильтр по Пользователю",
    },
    {
        name: "created_at",
        title: "Фильтр по Дате",
    },
    {
        name: "send_date",
        title: "Фильтр по Дате отправке",
    },
    {
        name: "custom_param1",
        title: "Фильтр по Custom 1",
    },
    {
        name: "custom_param2",
        title: "Фильтр по Custom 2",
    },
    {
        name: "custom_param3",
        title: "Фильтр по Custom 3",
    },
    {
        name: "custom_param4",
        title: "Фильтр по Custom 4",
    },
    {
        name: "custom_param5",
        title: "Фильтр по Custom 5",
    },
    {
        name: "custom_param6",
        title: "Фильтр по Custom 6",
    },
];