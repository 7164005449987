import { Box, Button, Popover, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import ReplayIcon from '@mui/icons-material/Replay';
import CachedIcon from '@mui/icons-material/Cached';
import PopoverContentExclude from "./PopoverContentExclude";
import CustomSelect from "./CustomSelect";

const useStyles = makeStyles((theme) => ({
  contTools: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    padding: "1.25rem",
    boxShadow: "0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16)",
    backgroundColor: "#FFF",
    borderRadius: "0.375rem",
  },
  popover: {
    display: "flex",
    padding: "20px",
  },
  buttSaved: {
    margin: "0px 10px!important",
    height: "40px",
    width:"80px",
    borderRadius: "20px",
  },
}));

function Tools({ 
  openExclude,
  handleOpenExclude, 
  exclude,
  handleExclude,
  handleOpenFilters, 
  exportToCSV, 
  constructOptionsFilters,
  autoReload, 
  handleAutoReload,
  hold,
  currentFilter,
  handleCurrentFilter,
  handleReload,
  resetFilters,
}) {
  const classes = useStyles();

  const reset = () => {
    resetFilters();
    handleCurrentFilter();
  }

  return (
    <Box className={classes.contTools}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <Tooltip title="Показывать варианты">
          <Button 
            onClick={handleOpenExclude}
            sx={{
              width: "46px",
              height: "38px",
              border: "1px solid #4669FA",
            }}
          >
            <LibraryAddCheckIcon sx={{ margin: "0 !important", fill: "#4669FA" }} />
          </Button>
        </Tooltip>
        <Tooltip title="Фильтры">
          <Button 
            onClick={handleOpenFilters}
            sx={{
              width: "46px",
              height: "38px",
              border: "1px solid #4669FA",
            }}
          >
            <FilterAltIcon sx={{ margin: "0 !important", fill: "#4669FA" }} />
          </Button>
        </Tooltip>
        <Tooltip title="Excel export">
          <Button 
            onClick={exportToCSV}
            sx={{
              width: "46px",
              height: "38px",
              border: "1px solid #4669FA",
            }}
          >
            <SystemUpdateAltIcon sx={{ margin: "0 !important", fill: "#4669FA" }} />
          </Button>
        </Tooltip>
        <Box>
          <CustomSelect
            options={constructOptionsFilters()}
            callback={(val) => handleCurrentFilter(val, true)}
            placeholder={"Без шаблона"}
            value={currentFilter}
            sx={{ backgroundColor: "#FFF", border: "1px solid rgba(70, 105, 250, 0.5)" }}
          />
        </Box>
        <Tooltip title="Очистить фильтры">
          <Button 
            onClick={reset}
            sx={{
              width: "46px",
              height: "38px",
              border: "1px solid rgb(241, 89, 92)",
            }}
          >
            <LayersClearIcon sx={{ margin: "0 !important", fill: "rgb(241, 89, 92)" }} />
          </Button>
        </Tooltip>
        <Tooltip title="Reload">
          <Button 
            onClick={handleReload}
            sx={{
              width: "46px",
              height: "38px",
              border: "1px solid #4669FA",
            }}
          >
            <ReplayIcon sx={{ margin: "0 !important", fill: "#4669FA" }} />
          </Button>
        </Tooltip>

        <Tooltip title="Auto reload">
          <Button
            sx={{
              width: "46px",
              height: "38px",
              border: autoReload ? "1px solid #4669FA" : "none",
              padding: autoReload ? "2px" : "5px",
              cursor: "pointer",
            }}
            onClick={handleAutoReload}
          >
            <CachedIcon sx={{ margin: "0 !important", fill: "#4669FA" }} />
          </Button>
        </Tooltip>
      </Box>

      {hold ? <Box><b>Hold: {hold}</b></Box> : null}

      <Popover
        open={Boolean(openExclude)}
        onClose={handleOpenExclude}
        anchorEl={openExclude}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box className={classes.popover}>
          <PopoverContentExclude
            callback={handleExclude}
            exclude={exclude}
          />
        </Box>
      </Popover>
    </Box>
  );
}

export default Tools;